import { fetchAuthSession } from "aws-amplify/auth"

import { Navigate, useLocation } from "react-router-dom"
import { useState, useEffect, useMemo } from "react"

import Loading from "./common/Loading"
import { API_SERVER } from "../config/constants"
import { callbackURL } from "../utils/common"

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function LinkedInCallback() {
  const [isLoading, setIsLoading] = useState(false)
  let query = useQuery()

  useEffect(() => {
    setIsLoading(true)
    fetchAuthSession().then((session) => {
      fetch(API_SERVER+'/apps/linkedin/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`
        },
        body: JSON.stringify({
          'code': query.get('code'),
          'callback_url': callbackURL('LinkedIn')
        }),
      })
      .then((response) => {
        setIsLoading(false)
        if (response.ok) {
          return response.json()
        }
        throw new Error('Something went wrong')
      })
      .then((data) => {

      }).catch((error) => {

      })
    })
  }, [query])

  return (
    isLoading ?
      <Loading/>
      :
      <Navigate to='/mkt/settings'/>
  )
}

export default LinkedInCallback
