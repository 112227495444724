import { useEffect, useState, useRef, useContext, useMemo } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as dayjs from "dayjs";
import Button from "./common/Button";
import Typing from "./Typing";
import { API_SERVER } from "./../config/constants";
import { AuthContext } from "../AuthProvider";
import PostPreviewSide from "./PostPreviewSide";
import { ReactComponent as AgentAvatar } from "../assets/agent_avatar.svg";
import { ReactComponent as ArrowBack } from "../assets/arrow_back.svg";
import { ReactComponent as MoreVertIcon } from "../assets/more_vert.svg";
import { Modal } from "flowbite-react";
import { fetchCampaign, createAssistantMessage } from "../services";

export const fetchCampaign1 = async (campaignId) => {
  const session = await fetchAuthSession();
  const data = await fetch(
    API_SERVER+`/multicampaigns/${campaignId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    }
  );

  const json = await data.json();
  return json;
};

let valu = 0;

function PostAssistantStep(props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [message, setMessage] = useState("");
  const [localMessage, setLocalMessage] = useState([]);
  const [isWatingAssistant, setIsWatingAssistant] = useState(false);
  const [threadId, setThreadID] = useState(null);
  const [chatAI, setChatAI] = useState("openAI");
  const [isAssistantFailed, setIsAssistantFailed] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  let { campaignId } = useParams();
  let { active_thread_id } = useParams();

  const getAIModel = {
    openAI: (message) => sendMessage(message),
    groqAI: (message) => sendMessageWithGrog(message),
  };

  const handleChatAI = (chatAI) => {
    setChatAI(chatAI);
  };

  const campaignResults = useQuery({
    queryKey: [`campaign-${campaignId}`],
    queryFn: async () => fetchCampaign(campaignId),
    staleTime: 10 * 1000,
  });

  const campaignResults1 = useQuery({
    queryFn: async () => fetchCampaign1(campaignId),
  });

  const canEdit = useMemo(() => {
    return campaignResults.data.state !== "PUBLISHED";
  }, [campaignResults.data.state]);

  if (!active_thread_id) {
    active_thread_id = campaignResults.data.active_thread_id;
  }
  const messagesResults = useQuery({
    queryKey: [`messages-${campaignId}`],
    queryFn: async () => {
      const session = await fetchAuthSession();
      const data = await fetch(
        API_SERVER+
          `/campaigns/${campaignId}/assistant/thread/${active_thread_id}/messages`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.tokens.idToken.toString()}`,
          },
        }
      );

      const json = await data.json();

      return json;
    },
  });

  const elementRef = useRef();

  let auth = useContext(AuthContext);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    if (messagesResults.data.length <= 0) {
      setLocalMessage((prev) => [
        ...prev,
        {
          type: "assistant",
          message_type: "text",
          message: `Hi ${auth.user.given_name.trim()}, what event do you want to cover?`,
        },
      ]);
    }
    if (campaignResults.data.active_thread_id?.includes("groq")) {
      setChatAI("groqAI");
    }

    elementRef.current.scrollIntoView();
  }, [auth.user.given_name]);

  useEffect(() => {
    elementRef.current.scrollIntoView();
  }, []);

  const sendMessage = (_message) => {
    setIsAssistantFailed(false);
    setLocalMessage((prev) => [
      ...prev,
      {
        type: "user",
        message: _message,
      },
    ]);
    setMessage("");
    setIsWatingAssistant(true);

    elementRef.current.scrollIntoView();
    fetchAuthSession().then((session) => {
      fetch(
        API_SERVER+
          `/campaigns/${campaignResults1.data[valu].id}/assistant/message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.tokens.idToken.toString()}`,
          },
          body: JSON.stringify({
            message: _message,
            thread_id: campaignResults.data.active_thread_id || threadId,
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong");
        })
        .then((data) => {
          setThreadID(data.thread_id);
          pollForCompletion(data.thread_id, data.run_id);
          elementRef.current.scrollIntoView();
        })
        .catch((error) => {
          setIsWatingAssistant(false);
          setIsAssistantFailed(true);
          elementRef.current.scrollIntoView();
        });
    });
  };
  const toggleOptions = (index) => {
    setOpenDropdown(openDropdown === index ? null : index); // Toggle the dropdown for the clicked row
  };
  const sendMessageWithGrog = async (_message) => {
    if (!_message) {
      return;
    }
    setLocalMessage((prev) => [
      ...prev,
      {
        type: "user",
        message: _message,
      },
    ]);
    setMessage("");
    setIsWatingAssistant(true);
    const { data, thread_id } = await createAssistantMessage(
      campaignId,
      _message
    );
    setThreadID(thread_id);
    queryClient.setQueryData([`messages-${campaignId}`], data);

    const lastMessage = data[data.length - 1];
    setLocalMessage([]);

    if (lastMessage.message_type === "json") {
      queryClient.setQueryData([`campaign-${campaignId}`], (old) => {
        return {
          ...old,
          post_social_network: lastMessage.message.social_network,
          post_caption: lastMessage.message.caption,
          post_image_prompt: lastMessage.message.image_prompt,
        };
      });
    }
    setIsWatingAssistant(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getAIModel[chatAI](message);
    }
  };

  const getTomorrowDate = () => {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate());

    let year = tomorrow.getFullYear();
    let month = ("0" + (tomorrow.getMonth() + 1)).slice(-2); // Months are 0-based
    let day = ("0" + tomorrow.getDate()).slice(-2);

    return year + "-" + month + "-" + day;
  };
  const formatWithOrdinal = (dateStr) => {
    const date = new Date(dateStr);
    
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const day = date.getDate();
    const year = date.getFullYear().toString();
    
    const hours = date.getHours();
    const minutes = date.getMinutes();
    
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format
    const formattedMinutes = minutes.toString().padStart(2, '0');
    
    // Function to get ordinal suffix
    const getOrdinalSuffix = (n) => {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0];
    };
  
    const formattedDate = `${monthNames[date.getMonth()]} ${day}${getOrdinalSuffix(day)} ${year} ${formattedHours}:${formattedMinutes} ${period}`;
    return formattedDate;
  };

  function formatDateString(dateStr) {
    return formatWithOrdinal(dateStr);
  }

  const pollForCompletion = (threadId, runId) => {
    fetchAuthSession().then((session) => {
      fetch(
        API_SERVER+
          `/campaigns/${campaignResults1.data[valu].id}/assistant/thread/${threadId}/message/${runId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.tokens.idToken.toString()}`,
          },
        }
      )
        .then((response) => {
          if (response.status === 202) {
            setTimeout(() => pollForCompletion(threadId, runId), 1000);
          } else if (response.status === 200) {
            response.json().then((data) => {
              queryClient.setQueryData([`messages-${campaignId}`], data);

              const lastMessage = data[data.length - 1];
              setLocalMessage([]);

              if (lastMessage.message_type === "json") {
                queryClient.setQueryData([`campaign-${campaignId}`], (old) => {
                  return {
                    ...old,
                    post_social_network: lastMessage.message.social_network,
                    post_caption: lastMessage.message.caption,
                    post_image_prompt: lastMessage.message.image_prompt,
                  };
                });
              }

              setIsWatingAssistant(false);
            });
          } else {
            console.error("Error or different status code: ", response.status);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch: ", error);
        });
    });
  };

  const deleteThread = (event) => {
    fetchAuthSession().then((session) => {
      fetch(
        API_SERVER+
          "/assistant/thread/" +
          (campaignResults.data.active_thread_id || threadId),
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${session.tokens.idToken.toString()}`,
          },
        }
      ).then(() => {
        setLocalMessage([]);
      });
    });
  };

  const [openModal, setOpenModal] = useState(false);

  const [newCampaign, setNewCampaign] = useState({
    id: "",
    start_date: getTomorrowDate(),
    title: "",
    type: "SINGLE",
    multi_id: "",
  });

  const [inputValid, setInputValid] = useState({
    title: true,
    start_date: true,
  });

  const createCampaign = () => {
    const isTitleValid = newCampaign.title.trim() !== "";
    const isStartDateValid = newCampaign.start_date !== "";
    const istypeValid = (newCampaign.type = "MULTI");
    const ismultiidValid = (newCampaign.multi_id = campaignId);

    // Update inputValid state based on validation
    setInputValid({
      title: isTitleValid,
      start_date: isStartDateValid,
      // Add other fields as needed
    });

    if (!newCampaign.title.trim()) {
      inputValid.title = false;
      return;
    }

    fetchAuthSession().then((session) => {
      fetch(API_SERVER+`/multicampaigns`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(newCampaign),
      })
        .then((response) => {
          setOpenModal(false);
          campaignResults1.refetch();
          // refetch();
        })
        .catch((error) => {
          console.error("Failed to fetch: ", error);
        });
    });
  };

  const handleItemClick = (event, index) => {
    if (campaignResults1.data) {
      setSelectedIndex(index);
      valu = index;
      campaignId = campaignResults1.data[index].id;
      active_thread_id = campaignResults1.data[index].active_thread_id;
      campaignResults.refetch();
      messagesResults.refetch();
    } else {
    }
  };
  const isDuplicate = campaignResults1.data.some(
    (item) => item.title === campaignResults.data.title
  );

  // If it's not a duplicate, add the element
  if (!isDuplicate) {
    campaignResults1.data.push(campaignResults.data);
  }
  window.addEventListener("popstate", () => {
    window.location.href = "/mkt/campaigns";
    window.location.reload();
  });

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const deletecampaign = (post) => {
    fetchAuthSession()
      .then((session) => {
        return fetch(API_SERVER+`/delete/${post.id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.tokens.idToken.toString()}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // Campaign deleted successfully
            setOpenModal(false);
            // refetch();
          })
          .catch((error) => {
            console.error("Failed to delete campaign: ", error);
          });
      })
      .catch((error) => {
        console.error("Failed to fetch auth session: ", error);
      });
  };

  return (
    <div className="flex flex-row w-full">
      <div className="h-full w-full flex flex-col justify-between">
        <div className="flex flex-row p-4 justify-between border-b-very-light-blue border-b">
          <div className="flex flex-row items-center">
            <ArrowBack
              onClick={() => {
                navigate("/mkt/campaigns");
                window.location.reload();
              }}
              className="hover:cursor-pointer"
            />
            <h1 className="pl-6 text-xl font-semibold">
              {campaignResults.data.title}
            </h1>
            <div className="w-4 h-[0px] rotate-90 border border-gray-100"></div>
            <div className="max-md:hidden text-main-gray text-sm font-medium leading-snug tracking-tight">
              {formatDateString(campaignResults.data.created_at)}
            </div>
          </div>
          <div className="flex flex-row space-x-3 max-md:hidden">
            {/* <Button outline onClick={deleteThread}>
              Cancel
            </Button> */}
          </div>
        </div>
        <div className="max-md:hidden flex flex-row">
          {campaignResults.data.type === "MULTI" ? (
            <>
              <div className="flex flex-col w-1/3 h-full p-4">
                <Button outline onClick={() => setOpenModal(true)}>
                  + New Post
                </Button>

                <ul className="flex flex-col h-[calc(100vh-250px)] max-lg:h-[calc(100vh-175px)] overflow-y-auto">
                  {campaignResults1.data.map((post, index) => (
                    <a key={index} onClick={() => toggleOptions(index)}>
                    <li
                      className={`flex px-3 py-4 rounded-md mt-4 relative ${
                        selectedIndex === index || (selectedIndex === null && index === campaignResults1.data.length-1) ? 'bg-blue-300' : 'bg-blue-100'
                      }`}
                    >
                      <span className="flex-grow">{post.title}</span>
                      <span className="absolute top-5 right-5">
                        <MoreVertIcon />
                      </span>
                      {openDropdown === index && (
                        <div className="absolute z-10 mt-2 w-48 bg-white rounded-lg shadow-lg">
                          <div className="py-1">
                            <div
                              onClick={(event) => handleItemClick(event, index)}
                              className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                            >
                              Edit
                            </div>
                            <div
                              onClick={() => {
                                setOpenDropdown(null);
                                deletecampaign(post);
                              }}
                              className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                            >
                              DELETE
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  </a>                 
                  ))}
                </ul>
              </div>
              <div className="flex flex-col w-2/3 h-full p-4">
                <ul className="flex flex-col h-[calc(100vh-250px)] max-lg:h-[calc(100vh-175px)] overflow-y-auto">
                  {(messagesResults.data || [])
                    .concat(localMessage)
                    .map((m, i) => (
                      <li
                        key={i}
                        className={
                          m.type === "user"
                            ? "flex flex-row mt-3"
                            : "flex flex-row mt-3 rounded-xl bg-very-light-blue"
                        }
                      >
                        <span className="p-2">
                          {m.type === "user" ? (
                            <div className="w-8 h-8 p-2.5 bg-main-blue rounded-[50px] flex-col justify-center items-center gap-2.5 inline-flex">
                              <div className="text-center text-white text-base font-normal leading-normal tracking-wide">
                                {auth.user.given_name[0]}
                                {auth.user.family_name[0]}
                              </div>
                            </div>
                          ) : (
                            <AgentAvatar className="bg-black rounded-3xl w-8 h-8 px-1 py-1 shadow-transparent" />
                          )}
                        </span>
                        <span className="p-2 pt-2 inline-flex items-center">
                          {typeof m.message === "object"
                            ? m.message.assistant_message
                            : m.message}
                        </span>
                      </li>
                    ))}

                  {isWatingAssistant && (
                    <li className="flex flex-row mt-3 rounded-xl bg-very-light-blue">
                      <span className="py-4 pl-3">
                        <Typing />
                      </span>
                    </li>
                  )}
                  {isAssistantFailed && (
                    <li className="flex flex-row mt-3 rounded-xl bg-very-light-blue">
                      <span className="p-2">
                        <AgentAvatar className="bg-black rounded-3xl w-8 h-8 px-1 py-1 shadow-transparent" />
                      </span>
                      <span className="p-2">
                        <span className="pr-2">
                          It seems like something whent wrong.
                        </span>
                        <Button onClick={() => sendMessage("retry")}>
                          Retry
                        </Button>
                      </span>
                    </li>
                  )}
                  <li ref={elementRef}></li>
                </ul>
                <div className="relative pt-4">
                  <textarea
                    value={message}
                    placeholder="Ask or write anything"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    disabled={isWatingAssistant || !canEdit}
                    rows="1"
                    class="block w-full p-4 border rounded-xl sm:text-md focus:outline-none resize-none custom-textarea"
                  ></textarea>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col w-2/3 h-full p-4">
              <ul className="flex flex-col h-[calc(100vh-250px)] max-lg:h-[calc(100vh-175px)] overflow-y-auto">
                {(messagesResults.data || [])
                  .concat(localMessage)
                  .map((m, i) => (
                    <li
                      key={i}
                      className={
                        m.type === "user"
                          ? "flex flex-row mt-3"
                          : "flex flex-row mt-3 rounded-xl bg-very-light-blue"
                      }
                    >
                      <span className="p-2">
                        {m.type === "user" ? (
                          <div className="w-8 h-8 p-2.5 bg-main-blue rounded-[50px] flex-col justify-center items-center gap-2.5 inline-flex">
                            <div className="text-center text-white text-base font-normal leading-normal tracking-wide">
                              {auth.user.given_name[0]}
                              {auth.user.family_name[0]}
                            </div>
                          </div>
                        ) : (
                          <AgentAvatar className="bg-black rounded-3xl w-8 h-8 px-1 py-1 shadow-transparent" />
                        )}
                      </span>
                      <span className="p-2 pt-2 inline-flex items-center">
                        {typeof m.message === "object"
                          ? m.message.assistant_message
                          : m.message}
                      </span>
                    </li>
                  ))}

                {isWatingAssistant && (
                  <li className="flex flex-row mt-3 rounded-xl bg-very-light-blue">
                    <span className="py-4 pl-3">
                      <Typing />
                    </span>
                  </li>
                )}
                {isAssistantFailed && (
                  <li className="flex flex-row mt-3 rounded-xl bg-very-light-blue">
                    <span className="p-2">
                      <AgentAvatar className="bg-black rounded-3xl w-8 h-8 px-1 py-1 shadow-transparent" />
                    </span>
                    <span className="p-2">
                      <span className="pr-2">
                        It seems like something whent wrong.
                      </span>
                      <Button onClick={() => sendMessage("retry")}>
                        Retry
                      </Button>
                    </span>
                  </li>
                )}
                <li ref={elementRef}></li>
              </ul>
              <div className="relative pt-4">
                <textarea
                  value={message}
                  placeholder="Ask or write anything"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  disabled={isWatingAssistant || !canEdit}
                  rows="1"
                  className="block w-full p-4 border rounded-xl sm:text-md focus:outline-none resize-none custom-textarea"
                ></textarea>
              </div>
            </div>
          )}
          <PostPreviewSide
            campaign={campaignResults.data}
            setChat={handleChatAI}
            chat={chatAI}
            isModelChangeable={messagesResults.data.length > 0}
            className="m-4 w-1/3 rounded-3xl border border-mid-light-gray shadow-sm"
          />
        </div>
        <div className="md:hidden flex flex-row bg-transparent text-2xl align-middle items-center p-10 max-md:h-[calc(100vh-70px)] text-center">
          This functionality was designed mainly for tablets and desktops
        </div>
      </div>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header className="text-center">Create Campaign</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div className="relative">
              <input
                type="text"
                id="campaign_title"
                value={newCampaign.title}
                onChange={(e) =>
                  setNewCampaign({ ...newCampaign, title: e.target.value })
                }
                className={`relative ${
                  !inputValid.title
                    ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-red-500 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                    : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                }`}
                placeholder=" "
              />
              <label
                htmlFor="campaign_title"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Title
              </label>
            </div>
            {/* <div className="relative">
              <input type="date" value={newCampaign.start_date} onChange={(e) => setNewCampaign({ ...newCampaign, start_date: e.target.value })} min={getTomorrowDate()} id="campaign_start_date" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer" placeholder=" " />
              <label htmlFor="campaign_start_date" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                Start Date
              </label>
            </div> */}
            {/* <fieldset className="flex max-w-md flex-row gap-4">
              <legend className="mb-4 font-semibold">Campaign Type</legend>
              <div className="flex items-center gap-2">
                <Radio id="single" name="postType" value={newCampaign.type = "SINGLE"} defaultChecked />
                <Label htmlFor="single">Single Post</Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio id="multi" name="postType" value={newCampaign.type = "MULTI"} />
                <Label htmlFor="multi">
                  Multiple Post
                </Label>
              </div>
            </fieldset> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-full flex flex-row justify-end space-x-3">
            <Button outline onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={createCampaign}>Create</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PostAssistantStep;
