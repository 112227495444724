import { toPairs } from "lodash"

import { useState, useMemo } from "react"

import { ReactComponent as LinkedIn } from '../assets/linkedin_icon.svg'
import { ReactComponent as Facebook } from '../assets/facebook_icon.svg'
import { ReactComponent as ArrowDown } from '../assets/arrow_drop_down.svg'

const compMap = {
  'LinkedIn': LinkedIn,
  'Facebook': Facebook
}

function SocialNetworkSelector({ value, handleSocialNetwork, disabled, className }) {
  const Icon = compMap[value] ?? LinkedIn
  const [showDropDown, setShowDropDown] = useState(false)

  const dropDownState = useMemo(() => {
    return showDropDown ? 'flex' : 'hidden'
  }, [showDropDown])

  return (
      disabled ?
      <div className={className + ' relative flex flex-row justify-between p-2.5'}>
        <Icon/>
      </div>
      :
      <div onClick={() => setShowDropDown(!showDropDown)} className={className + ' relative flex flex-row justify-between p-2.5 hover:cursor-pointer'}>
        <Icon/>
        <ArrowDown className='text-gray-500'/>
        <div className={dropDownState + ' absolute overflow-hidden flex-col -bottom-9 -left-0 w-24 min-h-11 border rounded-3xl bg-white z-20'}>
          {
            toPairs(compMap).map(
              ([key, Item]) => <Item key={key} onClick={() => handleSocialNetwork(key)} className={
                value === key ?
                  'bg-light-blue h-10 p-1.5 w-full mr-auto hover:bg-main-blue'
                :
                  'w-full h-10 p-1.5 mr-auto hover:bg-main-blue'
              }/>
            )
          }
        </div>
      </div>
  )
}

export default SocialNetworkSelector
