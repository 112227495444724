import { Modal, Radio, Label } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import * as dayjs from 'dayjs';
import { ReactComponent as MoreVertIcon } from '../assets/more_vert.svg';

import { useQuery } from '@tanstack/react-query';

import { fetchAuthSession } from 'aws-amplify/auth';

import Button from './common/Button';
import { API_SERVER } from '../config/constants'
import calactive from '../assets/cal_active.svg'
import calinactive from '../assets/cal_inactive.svg'

import listactive from '../assets/list_active.svg'
import listinactive from '../assets/list_inactive.svg'

const getTomorrowDate = () => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());

  let year = tomorrow.getFullYear();
  let month = ('0' + (tomorrow.getMonth() + 1)).slice(-2); // Months are 0-based
  let day = ('0' + tomorrow.getDate()).slice(-2);

  return year + '-' + month + '-' + day;
};

function formatDateString(dateStr) {
  return dayjs(dateStr).format('DD/MM/YYYY hh:mm A');
}

const stateMap = {
  'PUBLISHED': {
    'title': 'Published',
    'classNameOuter': 'bg-green-100',
    'classNameInner': 'text-green-500',
  },
  'DRAFT': {
    'title': 'Draft',
    'classNameOuter': 'bg-yellow-100',
    'classNameInner': 'text-yellow-500',
  },
  'ERROR': {
    'title': 'ERROR',
    'classNameOuter': 'bg-red-100',
    'classNameInner': 'text-red-500',
  },
  'DELETED': {
    'title': 'DELETED',
    'classNameOuter': 'bg-red-100',
    'classNameInner': 'text-red-500',
  }
};

function Campaigns({ className }) {
  const navigate = useNavigate();

  const { data: campaigns, refetch } = useQuery({
    queryKey: ['campaigns'],
    queryFn: async () => {
      const session = await fetchAuthSession();
      const data = await fetch(API_SERVER+`/campaigns`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`
        },
      });

      return await data.json();
    },
  });

  const [newCampaign, setNewCampaign] = useState({
    id: '',
    start_date: new Date().toISOString().split('T')[0],
    title: '',
    type: 'SINGLE'
  });
  const [openModal, setOpenModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null); // Track the open dropdown

  const [inputValid, setInputValid] = useState({
    title: true,
    start_date: true,
    // Add other fields as needed
  });

  const createCampaign = () => {
    const isTitleValid = newCampaign.title.trim() !== '';
    const isStartDateValid = newCampaign.start_date !== '';

    // Update inputValid state based on validation
    setInputValid({
      title: isTitleValid,
      start_date: isStartDateValid,
      // Add other fields as needed
    });

    if (!isTitleValid || !isStartDateValid) {
      return; // Don't proceed if validation fails
    }

    fetchAuthSession()
      .then((session) => {
        return fetch(API_SERVER+`/campaigns`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.tokens.idToken.toString()}`
          },
          body: JSON.stringify(newCampaign)
        });
      })
      .then(response => response.json())
      .then(data => {
        // Campaign created successfully
        setOpenModal(false);
        refetch();

        // Capture the campaign ID
        const campaignId = data.id;

        // Navigate to the specified page using the captured ID
        navigate(`/mkt/campaigns/${campaignId}/assistant`);
      })
      .catch(error => {
        console.error("Failed to create campaign: ", error);
      });
  };

  const deletecampaign = (campaignid) => {
    fetchAuthSession()
      .then((session) => {
        return fetch(API_SERVER+`/delete/${campaignid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.tokens.idToken.toString()}`
          }
        });
      })
      .then(response => response.json())
      .then(data => {
        // Campaign created successfully
        setOpenModal(false);
        refetch();
      })
      .catch(error => {
        console.error("Failed to delete campaign: ", error);
      });
  };

  const toggleOptions = (id) => {
    setOpenDropdown(openDropdown === id ? null : id); // Toggle the dropdown for the clicked row
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [clickCount, setClickCount] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [filterType, setFilterType] = useState('All');

  const handleFilterChange = (type) => {
    setIsOpen(!isOpen)
    setFilterType(type);
    // Reset search input when filter changes
    setSearchInput('');
  };

  const filteredCampaigns = campaigns.filter(campaign => {
    // Check if campaign matches search input
    const searchTerm = searchInput.toLowerCase();
    const campaignTitle = campaign.title.toLowerCase();
    return campaignTitle.includes(searchTerm);
  }).filter(campaign => {
    // Filter campaigns based on filter type
    if (filterType === 'All') return true;
    return campaign.type === filterType || (filterType === 'Single' && campaign.type === 'SINGLE') || (filterType === 'Multiple' && campaign.type === 'MULTI');
  });


  const toggle = () => {
    setClickCount(clickCount + 1);
  };
  const calendarImage = clickCount % 2 === 0 ? calactive : calinactive;
  const listImage = clickCount % 2 === 0 ? listinactive : listactive;
  const handleTypeChange = (event) => {
    setNewCampaign({ ...newCampaign, type: event.target.value });
  };

  const listCampaigns = () => {
    // Filter campaigns based on the search input value
    // const filteredCampaigns = campaigns.filter(campaign => {
    //   // Convert both the search input value and campaign title to lowercase for case-insensitive comparison
    //   const searchTerm = searchInput.toLowerCase();
    //   const campaignTitle = campaign.title.toLowerCase();
    //   // Check if the campaign title contains the search input value
    //   return campaignTitle.includes(searchTerm);
    // });

    return filteredCampaigns.map((c, i) => (
      <tr key={i} className="hover:bg-light-blue hover:cursor-pointer border-b">
        <td onClick={() => navigate(`/mkt/campaigns/${c.id}/assistant`)} className="-ml-5 py-3 px-4">
          <div className="h-20 flex-col justify-center items-start gap-1 inline-flex">
            <div className="self-stretch text-black text-lg font-semibold leading-normal tracking-tight">{c.title}</div>
            <div className="self-stretch text-light-gray text-base font-normal leading-snug tracking-tight">{formatDateString(c.created_at)}</div>
          </div>
        </td>
        <td className="-ml-5 py-3 px-4">
          <div className={"h-6 px-3 rounded justify-start items-center gap-1.5 inline-flex " + stateMap[c.state].classNameOuter}>
            <div className={"text-center text-sm font-normal leading-tight " + stateMap[c.state].classNameInner}>{stateMap[c.state].title}</div>
          </div>
        </td>
        <td className="-ml-5 py-3 px-4">
          <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">0%</span>
          <span className="text-light-gray text-base font-normal leading-snug tracking-tight"> (0)</span>
        </td>
        <td className="-ml-5 py-3 px-4">
          <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">0%</span>
          <span className="text-light-gray text-base font-normal leading-snug tracking-tight"> (0)</span>
        </td>
        <td className="-ml-5 py-8 px-5 relative">
          <div className="flex justify-center items-center" onClick={() => toggleOptions(c.id)}>
            <MoreVertIcon />
          </div>
          {openDropdown === c.id && (
            <div className="absolute z-10 right-0 mt-2 w-48 bg-white rounded-lg shadow-lg">
              <div className="py-1">
                <div onClick={() => navigate(`/mkt/campaigns/${c.id}/assistant`)} className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer">Edit</div>
                <div onClick={() => { setOpenDropdown(null); deletecampaign(c.id) }} className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer">Delete</div>
              </div>
            </div>
          )}
        </td>
      </tr>
    ));
  };

  return (
    <div className={className + 'flex flex-col rounded-md shadow shadow-yet-yet-another-gray'}>
      <div className="flex flex-row p-4 justify-between">
        <h1 className="text-2xl font-bold">Campaigns</h1>
        {/* <div>
          <MoreVertIcon/>
          <MoreVertIcon/>
        </div> */}

        {/* Search bar */}
        <div className="relative">
          <input
            type="text"
            placeholder="Search"
            className="p-2 border border-gray-300 rounded-md mr-4"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          {/* Search icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 absolute right-5 top-5 -translate-y-1/2 text-gray-500 cursor-pointer"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            onClick={() => {/* Add your search functionality here */ }}
          >
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
          </svg>
        </div>

        {/* Filter bar */}
        <div className="relative">
          <input
            type="text"
            placeholder="Filter"
            className="p-2 border border-gray-300 rounded-md mr-4"
            value={filterType}
            readOnly
            onClick={() => setIsOpen(!isOpen)}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 absolute right-5 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer"
            viewBox="0 0 24 24"
            fill="currentColor"
            onClick={() => setIsOpen(!isOpen)}
          >
            <path
              fillRule="evenodd"
              d="M 6.293 7.293 a 1 1 0 0 1 1.414 0 L 10 9.586 l 2.293 -2.293 a 1 1 0 1 1 1.414 1.414 l -3 3 a 1 1 0 0 1 -1.414 0 l -3 -3 a 1 1 0 0 1 0 -1.414 z z"
              clipRule="evenodd"
            />
          </svg>
          {/* Dropdown for filter options */}
          {isOpen && (
            <div className="absolute top-full left-0 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
              <ul className="py-1">
                <li className="px-3 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleFilterChange('Single')}>Single</li>
                <li className="px-3 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleFilterChange('Multiple')}>Multiple</li>
                <li className="px-3 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleFilterChange('All')}>All</li>
              </ul>
            </div>
          )}
        </div>
        <div onClick={toggle} className="cursor-pointer flex">
          <img
            src={calendarImage}
            alt={clickCount % 2 === 0 ? "calendar active" : "calendar inactive"}
            className="relative"
            style={{ margin: '0 -5px' }}
          />
          <img
            src={listImage}
            alt={clickCount % 2 === 0 ? "list inactive" : "list active"}
            className="relative"
            style={{ margin: '0 -5px' }}
          />
        </div>

        <div>
          <Button onClick={() => setOpenModal(true)}>
            Create
          </Button>
        </div>
      </div>
      <div className='h-[calc(100vh-150px)] overflow-y-auto'>
        <table className="max-md:table-fixed overflow-auto w-full">
          <thead className="bg-yet-another-gray text-light-gray font-semibold m-4">
            <tr>
              {/* <td className="-ml-5 py-3 px-4 w-72">Subject</td> */}
              <td className="-ml-5 py-3 px-4 w-72">Title</td>
              <td className="-ml-5 py-3 px-4 w-32">Status</td>
              <td className="-ml-5 py-3 px-4 w-20">Opens</td>
              <td className="-ml-5 py-3 px-4 w-20">Clicks</td>
              <td className="-ml-5 py-3 px-4 w-20"></td>
            </tr>
          </thead>
          <tbody>
            {listCampaigns()}
          </tbody>
        </table>
      </div>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header className='text-center'>Create Campaign</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div className="relative">
              <input type="text" id="campaign_title" value={newCampaign.title} onChange={(e) => setNewCampaign({ ...newCampaign, title: e.target.value })} className={`relative ${!inputValid.title ? 'block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-red-500 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer' : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"}`} placeholder=" " />
              <label htmlFor="campaign_title" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                Title
              </label>
            </div>
            {/* <div className="relative">
              <input type="date" value={newCampaign.start_date} onChange={(e) => setNewCampaign({ ...newCampaign, start_date: e.target.value })} min={getTomorrowDate()} id="campaign_start_date" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer" placeholder=" " />
              <label htmlFor="campaign_start_date" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                Start Date
              </label>
            </div> */}
            <fieldset className="flex max-w-md flex-row gap-4">
              <legend className="mb-4 font-semibold">Campaign Type</legend>
              <div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="single"
                    name="postType"
                    value="SINGLE"
                    checked={newCampaign.type === "SINGLE"}
                    onChange={handleTypeChange}
                  />
                  <label htmlFor="single">Single Post</label>
                  <input
                    type="radio"
                    id="multi"
                    name="postType"
                    value="MULTI"
                    checked={newCampaign.type === "MULTI"}
                    onChange={handleTypeChange}
                  />
                  <label htmlFor="multi">Multiple Post</label>
                </div>
              </div>
            </fieldset>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='w-full   flex-row justify-end space-x-3'>
            <Button outline onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={createCampaign}>
              Create
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Campaigns;
