import { useEffect, useState } from "react";
import { fetchAuthSession } from "aws-amplify/auth";

import {
  API_SERVER,
  APPS_LINKEDIN_CLIENT_ID,
  APPS_FACEBOOK_CLIENT_ID,
  APPS_FACEBOOK_CONFIG_ID,
  APPS_X_CLIENT_ID,
} from "../config/constants";

import { callbackURL } from "../utils/common";

import Loading from "./common/Loading";
import Button from "./common/Button";

import { ReactComponent as LinkedIn } from "../assets/linkedin_icon.svg";
import { ReactComponent as x } from "../assets/x.svg";
import { ReactComponent as Facebook } from "../assets/facebook_icon.svg";
import { ReactComponent as Instagram } from "../assets/Instagram1.svg";

import { ListGroup } from "flowbite-react";

const compMap = {
  LinkedIn: LinkedIn,
  Facebook: Facebook,
  X: x, // TODO: We should add a svg for X icon
  Instagram: Instagram,
};

const connectMap = {
  LinkedIn: () => {
    const clientId = APPS_LINKEDIN_CLIENT_ID;
    const callbackUrl = callbackURL("LinkedIn");
    const scopes = "w_member_social%20openid%20profile";
    window.location = `https://www.linkedin.com/oauth/v2/authorization
?response_type=code
&client_id=${clientId}
&redirect_uri=${callbackUrl}
&state=foobar
&scope=${scopes}`;
  },
  Facebook: () => {
    const clientId = APPS_FACEBOOK_CLIENT_ID;
    const callbackUrl = callbackURL("Facebook");
    window.location = `https://www.facebook.com/dialog/oauth
?client_id=${clientId}&redirect_uri=${callbackUrl}
&response_type=code
&config_id=${APPS_FACEBOOK_CONFIG_ID}`;
  },
  Instagram: () => {
    const clientId = APPS_FACEBOOK_CLIENT_ID;
    const callbackUrl = callbackURL("Instagram");

    window.location = `https://www.facebook.com/dialog/oauth
?client_id=${clientId}&redirect_uri=${callbackUrl}
&response_type=code
&config_id=${APPS_FACEBOOK_CONFIG_ID}`;
  },
  X: () => {
    const clientId = APPS_X_CLIENT_ID;
    const callbackUrl = callbackURL("X");
    const scopes = "tweet.read%20tweet.write%20users.read";
    window.location = `https://twitter.com/i/oauth2/authorize
?response_type=code
&client_id=${clientId}
&redirect_uri=${callbackUrl}
&scope=${scopes}
&state=sentellent-app
&code_challenge=challenge
&code_challenge_method=plain`;
  },
};

function Settings(props) {
  const [isLoading, setIsLoading] = useState();
  const [apps, setApps] = useState([]);

  const fetchApps = () => {
    fetchAuthSession().then((session) => {
      fetch(API_SERVER+"/apps", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      })
        .then((response) => {
          setIsLoading(false);
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong");
        })
        .then((data) => {
          setApps(data);
        })
        .catch((error) => {});
    });
  };

  const disconnectMap = {
    LinkedIn: () => {
      setIsLoading(true);
      fetchAuthSession().then((session) => {
        fetch(API_SERVER+"/apps/linkedin/disconnect", {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${session.tokens.idToken.toString()}`,
          },
        })
          .then((response) => {
            fetchApps();
          })
          .catch((error) => {});
      });
    },
    Facebook: () => {
      setIsLoading(true);
      fetchAuthSession().then((session) => {
        fetch(API_SERVER+"/apps/facebook/disconnect", {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${session.tokens.idToken.toString()}`,
          },
        })
          .then((response) => {
            fetchApps();
          })
          .catch((error) => {});
      });
    },
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
    window.fbAsyncInit = function() {
      FB.init({
        appId      : '${APPS_FACEBOOK_CLIENT_ID}',
        xfbml      : true,
        version    : 'v18.0'
      });
      FB.AppEvents.logPageView();
    };

    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
    `;
    document.body.appendChild(script);

    setIsLoading(true);
    fetchApps();
  }, []);

  const getIcon = (name) => {
    const Icon = compMap[name];
    return <Icon className="mr-3" />;
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Settings</h1>
      <div className="text-sm font-semibold text-center text-gray-500 border-b border-gray-200">
        <ul className="flex flex-wrap -mb-px">
          <li className="me-2">
            <a
              href="#"
              className="inline-block py-3 text-main-blue border-b-[3px] border-main-blue rounded-t-lg active"
              aria-current="page"
            >
              Social Platform
            </a>
          </li>
        </ul>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <ul className="grid grid-cols-1 divide-y">
          {apps.map((a, i) => (
            <li key={i} className="flex flex-row justify-between py-6">
              <div className="inline-flex items-center">
                {getIcon(a.app)}
                {a.app}
              </div>
              <div>
                {a.active ? (
                  <div
                    onClick={disconnectMap[a.app]}
                    className="h-10 w-28 px-4 py-2.5 rounded-3xl border border-red-700 justify-start items-center hover:cursor-pointer"
                  >
                    <div className="text-center text-red-700 text-sm font-medium leading-snug">
                      Disconnect
                    </div>
                  </div>
                ) : (
                  <Button outline onClick={connectMap[a.app]}>
                    Connect
                  </Button>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Settings;
