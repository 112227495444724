import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "./../config/constants";

export const fetchCampaign = async (campaignId) => {
  const session = await fetchAuthSession();
  const data = await fetch(API_SERVER+`/campaigns/${campaignId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.tokens.idToken.toString()}`,
    },
  });

  const json = await data.json();

  return json;
};
