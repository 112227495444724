import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { cloneDeep, find } from "lodash";
import { createStore } from "polotno/model/store";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { DEFAULT_SECTIONS } from "polotno/side-panel";
import { Toolbar } from "polotno/toolbar/toolbar";
import { Workspace } from "polotno/canvas/workspace";
import { Tooltip } from "polotno/canvas/tooltip";
import { SidePanel } from "polotno/side-panel";

import { ResizeSection } from "./editor/ResizeSection";
import { TemplatesSectionBuilder, TEMPLATES } from "./editor/TemplatesSection";
import { GenAITextBoxBuilder } from "./editor/GenAITextBox";
import { ActionControlsBuilder } from "./editor/ActionControls";
import { API_SERVER, POLOTNO_KEY } from "./../config/constants";
import { fetchCampaign } from "../services";

import Loading from "./common/Loading";

import "@blueprintjs/core/lib/css/blueprint.css";

const SKIP_DEFAULT_SECTIONS = [
  "templates",
  "size",
  "background",
  "elements",
  "layers",
];

const store = createStore({
  key: POLOTNO_KEY,
  showCredit: true,
});

function PostEditStep(props) {
  const navigate = useNavigate();
  const { campaignId } = useParams();

  const queryClient = useQueryClient();

  const [currTemplate, setCurrTemplate] = useState(cloneDeep(TEMPLATES[0]));
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);

  const campaignResults = useQuery({
    queryKey: [`campaign-${campaignId}`],
    queryFn: async () => fetchCampaign(campaignId),
    staleTime: 10 * 1000,
  });

  const campaign = campaignResults.data;

  const templateChangeCallback = async (template) => {
    setIsTemplateLoading(true);

    const _template = cloneDeep(template);

    let pageTextPage = find(_template.pages, ["id", "page-text"]);

    if (!pageTextPage) {
      pageTextPage = {
        id: "page-text",
        children: [
          {
            id: "post",
            type: "text",
            width: _template.width,
            text: campaign.post_caption,
            fontSize: Math.min(_template.width, _template.height) * 0.01,
            styleEditable: false,
            resizable: false,
            draggable: false,
          },
        ],
      };
      _template.pages.unshift(pageTextPage);
    }

    const textLength = campaign.post_caption.length;
    const numLines = (campaign.post_caption.match(/\n/g) || []).length + 1;

    _template.pages[0].children[0].fontSize =
      Math.min(_template.width / textLength, _template.height / numLines) * 16;

    let promises = [];

    _template.pages[1].children.forEach((element) => {
      const defaultImage = campaign.post_image;
      const editImage = campaign.post_edit_image;

      if (editImage) {
        if (element.id === "image") {
          element.src = editImage;
        }
      } else {
        if (element.id === "image") {
          element.src = defaultImage;
        }
      }

      if (element.type === "text" && !pageTextPage) {
        promises.push(generateCaption(element, _template));
      }
    });

    return Promise.all(promises).then(() => {
      setIsTemplateLoading(false);
      setCurrTemplate(_template);

      return _template;
    });
  };

  useEffect(() => {
    if (campaign.post_edit_state) {
      setCurrTemplate(campaign.post_edit_state);
      templateChangeCallback(campaign.post_edit_state).then((t) => {
        store.loadJSON(t);
      });
    } else {
      templateChangeCallback(currTemplate).then((t) => {
        store.loadJSON(t);
      });
    }
  }, []);

  const generateCaption = (element, template) => {
    const _currElementData = template.pages[1].children.find(
      (e) => e.id === element.id
    );

    if (!_currElementData || _currElementData.id === "post") {
      return;
    }

    return fetchAuthSession().then((session) => {
      return fetch(API_SERVER+"/text/caption", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({
          post: campaign.post_caption,
          function: {
            type: "function",
            function: {
              name: "create_post",
              description: "Post card parameters",
              parameters: {
                type: "object",
                properties: {
                  caption: { type: "string", description: "Between 2-3 words" },
                },
              },
            },
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (element.hasOwnProperty("set")) {
            element.set({ text: data.choices[0].message.caption });
          } else {
            element.text = data.choices[0].message.caption;
          }
        });
    });
  };

  const updatedSections = DEFAULT_SECTIONS.filter((e) => {
    return !SKIP_DEFAULT_SECTIONS.includes(e.name);
  });
  updatedSections.push(ResizeSection);
  updatedSections.push(TemplatesSectionBuilder(templateChangeCallback));

  const cancelAction = () => {
    navigate(`/mkt/campaigns/${campaign.id}/assistant`);
  };

  const saveAction = async () => {
    setIsTemplateLoading(true);
    const session = await fetchAuthSession();
    const response = await fetch(
      API_SERVER+`/campaigns/${campaign.id}/posts/1`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({
          post_edit_state: store.toJSON(),
          post_edit_image: await store.toDataURL({
            pageId: "page-2-1",
            mimeType: "image/jpg",
          }),
        }),
      }
    );

    const data = await response.json();
    queryClient.setQueryData([`campaign-${campaign.id}`], (old) => {
      return { ...old, ...data };
    });

    navigate(`/mkt/campaigns/${campaign.id}/assistant`);
  };

  return (
    <section className="w-[calc(100vw-450px)] h-[calc(100vh-100px)]">
      <PolotnoContainer className="polotno-app-container">
        <SidePanelWrap>
          <SidePanel
            store={store}
            sections={updatedSections}
            defaultSection="custom-templates"
          />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar
            store={store}
            components={{
              ActionControls: ActionControlsBuilder(cancelAction, saveAction),
            }}
          />
          {isTemplateLoading && (
            <div
              style={{
                position: "absolute",
                top: "50px",
                zIndex: "20",
                width: "100%",
                height: "calc(100vh - 20px)",
              }}
              className="bg-gray-800 bg-opacity-50 backdrop-blur-sm flex justify-center items-center"
            >
              <Loading />
            </div>
          )}
          <Workspace
            store={store}
            components={{
              Tooltip,
              TextFill: GenAITextBoxBuilder((element) =>
                generateCaption(element, currTemplate)
              ),
              PageControls: () => null,
            }}
          />
        </WorkspaceWrap>
      </PolotnoContainer>
    </section>
  );
}

export default PostEditStep;
