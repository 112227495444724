import { random } from 'lodash'

import { useEffect, useRef } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { fetchAuthSession } from "aws-amplify/auth"

import { API_SERVER } from "./../../config/constants"

function Image(props) {
  const queryClient = useQueryClient()
  const prevPromptRef = useRef(props.campaign.post_image_prompt)

  const mutation = useMutation({
    mutationFn: async ({prompt, seed, steps = 100}) => {
      const session = await fetchAuthSession()
      const imageResp = await fetch(API_SERVER+`/campaigns/${props.campaign.id}/image/process`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.tokens.idToken.toString()}`
          },
          body: JSON.stringify({
            'prompt': prompt,
            'seed': seed,
            'steps': parseInt(steps)
          })
        })

      const imageObj = await imageResp.json()
      queryClient.setQueryData([`campaign-${props.campaign.id}`], (old) => {
        return {...old, post_image: imageObj.post_image}
      })
    }
  })

  const mutationEnhance = useMutation({
    mutationFn: async () => {
      const session = await fetchAuthSession()
      const imageResp = await fetch(API_SERVER+`/campaigns/${props.campaign.id}/image/enhance`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.tokens.idToken.toString()}`
          },
          body: JSON.stringify({
          })
        })

      const imageObj = await imageResp.json()
      queryClient.setQueryData([`campaign-${props.campaign.id}`], (old) => {
        return {...old, post_image: imageObj.post_image}
      })
    }
  })

  useEffect(() => {
    if (!props.campaign.post_image || props.campaign.post_image_prompt !== prevPromptRef.current) {
      mutation.mutate({prompt: props.campaign.post_image_prompt})
    }
  }, [props.campaign.post_image_prompt])

  return (
    <div>
      { mutation.isPending ?
        <div role="status" className="w-full h-full p-4 animate-pulse md:p-6 dark:border-gray-700">
          <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 dark:bg-gray-700">
            <svg className="w-10 h-10 text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
              <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"/>
              <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"/>
            </svg>
          </div>
        </div>
        :
        <div className="relative flex flex-row justify-center">
          { props.disabled &&
            <svg onClick={() => mutation.mutate({prompt: props.campaign.post_image_prompt, seed: random(0, 10000)})} className="absolute right-1 top-1 w-6 h-6 p-1 text-white bg-main-blue rounded-full hover:cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97"/>
            </svg>
          }
          {
            props.disabled &&
            <svg onClick={() => mutationEnhance.mutate()} className="absolute right-1 top-8 w-6 h-6 p-1 text-white bg-main-blue rounded-full hover:cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
              <path d="M12.356 5.435 1.938 16.384a.5.5 0 0 0 .018.707l2.9 2.757a.5.5 0 0 0 .706-.018L15.978 8.882l-3.622-3.447Zm7.681-.819a.5.5 0 0 0-.018-.706l-2.9-2.757a.5.5 0 0 0-.707.017l-2.68 2.817 3.622 3.446 2.683-2.817Zm-2.89 12.233-1 .025-.024-1a1 1 0 1 0-2 .05l.025 1-1 .024a1 1 0 0 0 .05 2l1-.025.025 1a1 1 0 1 0 2-.05l-.025-1 1-.024a1 1 0 1 0-.05-2h-.001ZM2.953 9.2l.025 1a1 1 0 1 0 2-.05l-.025-1 1-.025a1 1 0 1 0-.05-2l-1 .025-.025-1a1 1 0 0 0-2 .049l.025 1-1 .025a1 1 0 0 0 .05 2l1-.024Zm15.07 2.626-2 .05.05 1.999 2-.05-.05-1.999ZM11.752.978l-2 .05.05 2 2-.05-.05-2Zm-2.95 2.075-2 .05.05 1.999 2-.05-.05-2ZM5.753 1.127l-1.999.05.05 2 1.999-.05-.05-2Zm15.194 7.625-2 .05.05 2 2-.05-.05-2Zm.125 4.998-2 .05.05 2 2-.05-.05-2Z"/>
            </svg>
          }
          <img src={(props.campaign.post_edit_image || props.campaign.post_image)} alt={props.campaign.post_image_prompt}/>
        </div>
      }
    </div>
  )
}

export default Image