import Button from "../common/Button"

export function ActionControlsBuilder(cancelCallback, saveCallback) {

  const ActionControls = ({ store }) => {
    return (
      <div className="flex flex-row space-x-4">
        <Button outline onClick={() => cancelCallback()}>
          Cancel
        </Button>

        <Button onClick={() => saveCallback()}>
          Save
        </Button>
      </div>
    )
  }

  return ActionControls
}
