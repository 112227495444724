import React from 'react';
import styled from 'styled-components';
import x from './assets/x.png'
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ModalHeader = styled.div`
  background-color: #1da1f2;
  padding: 20px;
`;

const HeaderTitle = styled.h2`
  color: #fff;
  font-size: 22px;
  font-weight: 700;
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const CloseButton = styled.button`
  color: #000000;
  position: absolute;
  top: 1px; /* Adjust the top position */
  right: 5px; /* Adjust the right position */
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
`;

const InlineForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative; /* Set the form to relative position */
`;

const FormInput = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  width: 100%;
`;

const SubscribeButton = styled.button`
  padding: 12px 24px;
  background-color: #1da1f2;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-weight: bold;
`;

const Subheader = styled.div`
  color: #7d7d7d;
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const Modal = ({ onClose }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <CloseButton onClick={onClose}>  <img src={x} alt="Close" style={{ width: '15px', height: '15px' }} />
          </CloseButton> {/* Close button inside the form */}
          <HeaderTitle>Sign up for early access to Sentellent,<br /> the Gen AI Powered Marketing Platform</HeaderTitle>
        </ModalHeader>
        <Subheader>Let us know how we can get in touch:</Subheader>
        <IframeContainer>
          <InlineForm
            action="https://app.convertkit.com/forms/6265554/subscriptions"
            className="seva-form formkit-form"
            method="post"
            data-sv-form="6265554"
            data-uid="bbcefd39ae"
            data-format="modal"
            data-version="5"
            min-width="400"
          >
            <FormInput type="text" name="email_address" aria-label="Email Address" placeholder="Email Address" required />
            <FormInput type="text" name="fields[first_name]" aria-label="First Name" placeholder="First Name" />
            <FormInput type="text" name="fields[last_name]" aria-label="Last Name" placeholder="Last Name" />
            <FormInput type="text" name="fields[organization_names]" aria-label="Organization Name" placeholder="Organization Name" />
            <SubscribeButton type="submit">Subscribe</SubscribeButton>
          </InlineForm>
        </IframeContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
