import { useNavigate } from "react-router-dom"
import { Outlet, NavLink } from "react-router-dom"
import Loading from "./components/common/Loading"
import { Suspense } from "react"

import UserProfile from "./components/sidebar/UserProfile"

import { ReactComponent as Logo } from './assets/logo_small_24.svg'
import { ReactComponent as CampaignIcon } from './assets/campaign.svg'
import { ReactComponent as SettingsIcon } from './assets/settings.svg'


function Console() {
  const navigate = useNavigate()

  const sideBarNavLinkStyles = ({ isActive }) => {
    return isActive ?
      'self-stretch h-12 px-4 bg-indigo-50 text-main-blue rounded-lg justify-start items-center gap-6 inline-flex'
      :
      'self-stretch h-12 px-4 justify-start items-center gap-6 inline-flex'
  }

  return (
    <div className="relative w-screen h-screen flex flex-row justify-start items-center font-['Roboto']">
      <div className="max-lg:hidden w-80 self-stretch px-3 pt-6 pb-2 bg-white shadow shadow-very-light-gray-gray flex-col items-center gap-8 inline-flex z-10">
        <div onClick={() => navigate('../')} className="self-stretch text-main-blue pl-3.5 justify-start items-center gap-3 inline-flex hover:cursor-pointer">
          <Logo/>
          <div className="text-black text-2xl font-semibold leading-tight">Sentellent</div>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="self-stretch flex-col justify-start items-center gap-1 flex">
            <NavLink to="/mkt/campaigns" className={sideBarNavLinkStyles}>
              <CampaignIcon/>
              <div className="grow shrink basis-0 self-stretch py-3 flex-col justify-start items-start gap-0.5 inline-flex">
                <div className="self-stretch text-base font-normal leading-normal tracking-wide">Campaigns</div>
              </div>
            </NavLink>
            <NavLink to="/mkt/settings" className={sideBarNavLinkStyles}>
              <SettingsIcon/>
              <div className="grow shrink basis-0 self-stretch py-3 flex-col justify-start items-start gap-0.5 inline-flex">
                <div className="self-stretch text-base font-normal leading-normal tracking-wide">Settings</div>
              </div>
            </NavLink>
          </div>
          <UserProfile/>
        </div>
      </div>
      <div className="bg-clip-padding bg-very-light-gray w-full h-full px-20 py-10 max-lg:p-0">
        <div className="bg-white rounded-md">
          <Suspense fallback={<Loading />}>
            <Outlet/>
          </Suspense>
        </div>
      </div>
      <svg className="lg:hidden w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
      </svg>
    </div>
  )
}

export default Console
