export const POLOTNO_KEY = 'UI6sdqs5sQcuxfPbkc1x'

export const API_SERVER = `${process.env.REACT_APP_API_SERVER}`

export const COGNITO_REGION = 'us-east-1'
export const COGNITO_USER_POOL_ID = 'us-east-1_2LVVyRyxf'
export const COGNITO_USER_POOL_APP_CLIENT_ID = '6kgg4dfhptdodh7nqb8mrhf8eg'
export const COGNITO_DOMAIN = 'sentellent.auth.us-east-1.amazoncognito.com'

export const APPS_LINKEDIN_CLIENT_ID = '78k1ufnwo12azc'
// export const APPS_FACEBOOK_CLIENT_ID = '1026527958428250'
export const APPS_FACEBOOK_CLIENT_ID = `${process.env.REACT_APP_APPS_FACEBOOK_CLIENT_ID}`
export const APPS_FACEBOOK_CONFIG_ID = `${process.env.REACT_APP_APPS_FACEBOOK_CONFIG_ID}`

export const APPS_INSTAGRAM_CLIENT_ID = '359878223317407'
export const APPS_INSTAGRAM_CONFIG_ID = '581976657434110'

export const APPS_X_CLIENT_ID = `${process.env.REACT_APP_APPS_X_CLIENT_ID}`
