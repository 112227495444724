import React, {useEffect, Suspense } from 'react'
import { Amplify } from "aws-amplify"
import { Routes, Route } from "react-router-dom"
import { CookieStorage } from 'aws-amplify/utils'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import PostAssistantStep from './components/PostAssistantStep'
import PostEditStep from './components/PostEditStep'

import { AuthProvider, RequireAuth, AuthCallback } from './AuthProvider'
import LandingPage from './LandingPage'
import LoginPage from './LoginPage'
import Console from './Console'
import Settings from './components/Settings'
import LinkedInCallback from './components/LinkedInCallback'
import FacebookCallback from './components/FacebookCallback'
import InstagramCallback from './components/InstagramCallback'
import Loading from './components/common/Loading'
import Campaigns from './components/Campaigns'

import {
  COGNITO_DOMAIN, COGNITO_USER_POOL_APP_CLIENT_ID, COGNITO_USER_POOL_ID
} from './config/constants'

import './App.css'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: COGNITO_USER_POOL_ID,
      userPoolClientId: COGNITO_USER_POOL_APP_CLIENT_ID,

      loginWith: {
        oauth: {
          domain: COGNITO_DOMAIN,
          // TODO: revisit aws.cognito.signin.user.admin scope
          scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
          redirectSignIn: ["http://localhost:8080/auth/callback", "https://sentellent.com/auth/callback", "https://stg.sentellent.com/auth/callback"],
          redirectSignOut: ["http://localhost:8080/", "https://sentellent.com/","https://stg.sentellent.com/"],
          responseType: "token",
        },
      },

      cookieStorage: {
        domain: "localhost",
        path: "/",
        expires: 1,
        sameSite: "strict",
        secure: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN !== "localhost",
      },
    },
  }
})

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage())

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
})

function App() {
  useEffect(() => {
    var noHashURL = window.location.href.replace(/#.*$/, '');
    window.history.replaceState('', document.title, noHashURL)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
    <Suspense fallback={<Loading/>}>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/mkt" element={<RequireAuth><Console/></RequireAuth>}>
          <Route path="apps/linkedin/auth" element={<LinkedInCallback/>}/>
          <Route path="apps/facebook/auth" element={<FacebookCallback/>}/>
          <Route path="apps/instagram/auth" element={<InstagramCallback/>}/>
          <Route path="settings" element={<Settings/>}>
          </Route>
          <Route path="campaigns">
            <Route index element={<Campaigns/>} />
            <Route path=":campaignId/assistant" element={<PostAssistantStep/>}/>
            <Route path=":campaignId/edit" element={<PostEditStep/>}/>
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
    </Suspense>
    </QueryClientProvider>
  )
}

export default App
