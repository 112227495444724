import { useContext } from "react"
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom"
import { styled } from "styled-components"
import { AuthContext } from "./AuthProvider"
import Rectangle from './assets/Rectangle 45.svg'
import Rectangle2 from './assets/Rectangle 24.png'
import Rectangle1 from './assets/section.png'
import animationImage from './assets/animation2.gif'
import Section2img from './assets/section2img.png'
import Section3img from './assets/section3img.png'
import Section4img from './assets/section4img.png'
import Section5img from './assets/section5img.png'
import Rectangle5 from './assets/section5.png'
import Section6img from './assets/section6_img.png'
import Section6img1 from './assets/section6_img1.png'
import './style.css';
import { ReactComponent as Logo } from './assets/logo_small_24.svg'
import Button from "./components/common/Button"
import SVGButton from "./components/common/SVGButton"
import Rectangle10 from './assets/section10.png'
import Modal from "./Modal";

const Section1 = styled.div`
  background: url('${Rectangle1}');
  background-size: 100% 100%;
`
const Section2 = styled.div`
  background: url('${Rectangle}');
  background-size: 100% 100%;
`
const Section3 = styled.div`
  background-size: 100% 100%;
`
const Section4 = styled.div`
  background-size: 100% 100%;
`
const Section5 = styled.div`
  background: url('${Rectangle5}');
  background-size: 100% 100%;
`
const Section6 = styled.div`
  background: url('${Rectangle2}');
  background-size: 100% 100%;
`
const Section7 = styled.div`
  background-size: 100% 100%;
`
const Section10 = styled.div`
  background: url('${Rectangle10}');
  background-size: 100% 100%;
`

function LandingPage() {
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false);
  const redirectToAssitant = () => {
    navigate('/mkt/campaigns')
  }
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const [expandedSections, setExpandedSections] = useState({
    section1: false,
    section2: false,
    section3: false,
    section4: false,
    section5: false,
  });

  const toggleExpand = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div className="flex flex-col bg-white">
      {/* Header */}
      <div className="fixed top-0 z-50 w-full">
        <div className="flex justify-between items-center px-14 py-3 bg-white bg-opacity-80 backdrop-blur-[5px] shadow-sm max-md:px-3 max-md:max-w-full">
          <div className="flex items-center space-x-3 text-xl font-medium text-black">
            <Logo className="text-main-blue" />
            <div>Sentellent</div>
          </div>
          <div className="flex gap-20 justify-between items-center my-auto tracking-normal text-black max-md:flex-wrap max-md:max-w-full">
            <div className="flex justify-center items-center">
              <Link onClick={() => window.scrollTo({ top: 0, behavior: 'auto' })} className="text-black text-base font-medium tracking-tight">Home</Link>
            </div>
            <Link onClick={() => window.scrollTo({ top: 700, behavior: 'auto' })} className="text-black text-base font-medium tracking-tight">Features</Link>
            <Link onClick={() => window.scrollTo({ top: 4100, behavior: 'auto' })} className="text-black text-base font-medium tracking-tight">Pricing</Link>
            <Link onClick={openModal} className="text-black text-base font-medium tracking-tight">Join the Waitlist</Link>
          </div>
          <div className="flex justify-end items-center space-x-7 max-md:hidden">
            {auth.user ?
              <SVGButton onClick={redirectToAssitant} width={159}
                height={44}
                borderRadius={20}>
                Manage Campaigns
              </SVGButton>
              :
              <>
                <SVGButton onClick={redirectToAssitant} width={139}
                  height={44}
                  borderRadius={20}>
                  Beta User Sign In
                </SVGButton>
              </>
            }
          </div>
        </div>
      </div>

      {/* Header */}
      <Section1 className="flex flex-col justify-center self-stretch mt-10 w-full max-md:max-w-full">
        <div className="px-14 py-20 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch my-auto text-black max-md:mt-10 max-md:max-w-full">
                <div className="text-5xl font-bold leading-[60px] max-md:max-w-full max-md:text-4xl max-md:leading-[56px]">
                  The AI Marketing Tool You Need to Succeed
                </div>
                <div className="mt-8 text-xl tracking-wide max-md:max-w-full">
                  Create blogs, ad copies, emails, social media posts, and more in
                  seconds!
                </div>
                <div className="mt-12 max-md:mt-10">
                  <SVGButton onClick={openModal} width={256}
                    height={56}
                    borderRadius={10}>
                    Join the Waitlist
                  </SVGButton>
                  {showModal && (
                    <Modal onClose={closeModal}>
                    </Modal>
                  )}
                  <div className="mt-12 text-black text-base font-normal tracking-tight">❤️ Generative AI solutions for everyone</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <img
                src={animationImage}
                className="w-full aspect-[1.35] max-md:mt-10 max-md:max-w-full" style={{ borderRadius: '30px' }}
              />
            </div>
          </div>
        </div>
      </Section1>
      <Section2 className="flex flex-col items-center self-stretch px-16 pt-20 pb-12 w-full max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center px-20 py-20 w-full max-md:px-5 max-md:max-w-full">
          <div className="mt-3.5 text-5xl font-medium text-center text-black leading-[63px] max-md:max-w-full max-md:text-4xl max-md:leading-[56px]">
            <span className="">Why Choose </span>
            <span className="font-black">Sentellent</span>
            <span className="">?</span>
          </div>
          <div className="mt-5 text-base tracking-wide leading-7 text-center font-[450] text-black text-opacity-90 w-[643px] max-md:max-w-full">
            Choose AI-powered efficiency and creativity for seamless campaign
            creation, ensuring impactful results and a competitive edge for your
            brand.
          </div>
          <div className="self-stretch mt-16 max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[56%] max-md:ml-0 max-md:w-full">
                <img
                  src={Section2img}
                // className="grow w-full aspect-[1.25] max-md:mt-10 max-md:max-w-full"
                />
              </div>
              <div className="flex flex-col ml-5 w-[44%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col self-stretch my-auto text-base text-black max-md:mt-10 max-md:max-w-full">
                  <div className="text-4xl font-medium leading-10 max-md:max-w-full">
                    AI Marketing - Efficient, Effective, Economical!
                  </div>
                  <div className="mt-3.5 tracking-wide leading-7 font-[450] text-black text-opacity-90 max-md:max-w-full">
                    Revolutionize marketing: AI-driven efficiency slashes costs,
                    boosts ROI, and optimizes strategies for maximum impact and
                    savings.
                  </div>
                  <div onClick={openModal} className="text-2xl flex gap-4 self-start py-1 mt-8 font-bold tracking-normal text-sky-500 items-center">
                    <button>Join the Waitlist</button>
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e3fe1ef904485958c3d07832a06b38a46854583e08ea85c24ce40f29e0de1510?"
                      className="shrink-0 aspect-[1.22] fill-black h-6 w-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section2>
      <Section3 className="flex flex-col items-center self-stretch px-16 pt-20 pb-12 w-full max-md:px-5 max-md:max-w-full">
        <div className="self-center ml-5 w-full max-w-[1236px] max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[44%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch my-auto text-base max-md:mt-10 max-md:max-w-full">
                <div className="text-4xl font-medium leading-10 text-black max-md:max-w-full">
                  AI: Innovate Content, Engage Audiences, Drive Results
                </div>
                <div className="mt-3.5 tracking-wide leading-7 font-[450] text-black text-opacity-90 max-md:max-w-full">
                  Seamlessly craft impactful campaigns with AI: effortless, natural
                  conversations drive powerful results, no tech expertise needed for
                  success.
                </div>
                <div onClick={openModal} className="text-2xl flex gap-4 self-start py-1 mt-8 font-bold tracking-normal text-sky-500 items-center">
                  <button>Join the Waitlist</button>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e3fe1ef904485958c3d07832a06b38a46854583e08ea85c24ce40f29e0de1510?"
                    className="shrink-0 aspect-[1.22] fill-black h-6 w-auto"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[56%] max-md:ml-0 max-md:w-full">
              <img
                src={Section3img}
                className="grow w-full aspect-[1.25] max-md:mt-10 max-md:max-w-full"
              />
            </div>
          </div>
        </div>
      </Section3>
      <Section4 className="flex flex-col items-center self-stretch px-16 pt-20 pb-12 w-full max-md:px-5 max-md:max-w-full">
        <div className="mt-6 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[55%] max-md:ml-0 max-md:w-full">
              <div className="flex overflow-hidden relative flex-col grow justify-center min-h-[641px] max-md:mt-10 max-md:max-w-full">
                <img
                  src={Section4img}
                  className="object-cover absolute inset-0 size-full"
                />
                {/* <img
              src={Section4img}
              className="w-full aspect-[0.89] max-md:max-w-full"
            /> */}
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[45%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
                <div className="text-4xl font-medium leading-10 text-black max-md:max-w-full">
                  Tired of draining your wallet and sanity on endless social media
                  content creation?
                </div>
                <div className="mt-3.5 text-base tracking-wide leading-7 font-[450] text-black text-opacity-90 max-md:max-w-full">
                  Say hello to your new secret weapon: Sentellent, the AI-powered
                  marketing tool that supercharges your creativity and slashes your
                  social media expenses.
                </div>
                <div onClick={openModal} className="text-2xl flex gap-4 self-start py-1 mt-8 font-bold tracking-normal text-sky-500 items-center">
                  <button>Join the Waitlist</button>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e3fe1ef904485958c3d07832a06b38a46854583e08ea85c24ce40f29e0de1510?"
                    className="shrink-0 aspect-[1.22] fill-black h-6 w-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section4>
      <Section5 className="flex flex-col items-center self-stretch px-16 pt-20 pb-12 w-full max-md:px-5 max-md:max-w-full">
        <div className="py-20 pr-5 bg-slate-100">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <img
                src={Section5img}
                className="w-full aspect-[1.08] max-md:mt-10 max-md:max-w-full"
              />
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch my-auto font-medium text-black max-md:mt-10 max-md:max-w-full">
                <div className="text-5xl leading-[57px] max-md:max-w-full max-md:text-4xl max-md:leading-[51px]">
                  Effortless Content Creation with Generative AI:
                </div>
                <div className="mt-8 text-base tracking-wide leading-7 font-[450] text-black text-opacity-90 max-md:max-w-full">
                  Say goodbye to writer's block: Ditch the blank page and let
                  Sentellent's AI spark your creativity.
                </div>
                <div className="flex gap-5 justify-between mt-9 text-xl leading-6 max-md:flex-wrap">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f61811c43ea901f37923946946686775db5d980b570bc2a9a29c71660e1b00dd?"
                    className="shrink-0 self-start mt-1 aspect-[0.15] w-[41px]"
                  />
                  <div className="flex flex-col grow shrink-0 py-1 basis-0 w-fit max-md:max-w-full">
                    <div className="mt-2 max-md:max-w-full">
                      Seamless Scheduling Across Platforms
                    </div>
                    <div className="mt-5 text-sm tracking-wide leading-6 font-[450] text-black text-opacity-90 max-md:max-w-full">
                      One-stop shop for all your social media needs: Manage all your
                      social media accounts from a single platform.
                    </div>
                    <div className="mt-5 max-md:max-w-full">
                      Affordable and Scalable for Every Business
                    </div>
                    <div className="mt-5 text-sm tracking-wide leading-6 font-[450] text-black text-opacity-90 max-md:max-w-full">
                      Start small and grow big: Choose the plan that best suits your
                      needs and scale up as your business grows.
                    </div>
                    <div className="self-start mt-5 max-md:max-w-full">
                      Intuitive Chat-Based Interface
                    </div>
                    <div className="mt-5 text-sm tracking-wide leading-6 font-[450] text-black text-opacity-90 w-[449px] max-md:max-w-full">
                      Conversational AI for a seamless experience: Interact with
                      Sentellent just like you would chat with a friend.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section5>
      <Section6 className="flex flex-col items-center self-stretch px-16 pt-20 pb-12 w-full max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center px-20 py-20 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between items-start w-full text-center text-black max-w-[1135px] max-md:flex-wrap max-md:max-w-full">
            <img
              src={Section6img}
              className="shrink-0 self-end mt-9 aspect-[1.1] w-[82px]"
            />
            <div className="flex flex-col flex-1 self-start max-md:max-w-full">
              <div className="text-5xl font-medium leading-[57px] max-md:max-w-full max-md:text-4xl">
                Flexible pricing plans tailored for you
              </div>
              <div className="self-center mt-6 text-lg leading-7 max-md:max-w-full">
                Choose the best plan that’s suits your property management solution
                needs
              </div>
            </div>
          </div>
          <div className="flex gap-4 items-center px-7 py-6 mt-10 max-w-full whitespace-nowrap bg-white rounded-3xl border border-gray-100 border-solid shadow-2xl w-[974px] max-md:flex-wrap max-md:px-5 max-md:mt-10">
            <div className="flex flex-col self-stretch my-auto basis-0">
              <div className="text-xl font-bold text-sky-500">Free</div>
              <div className="flex gap-0.5 p-1.5 text-black">
                <div className="text-3xl font-medium tracking-tighter text-right">
                  $0
                </div>
                <div className="flex-auto self-start mt-3 text-base font-[450]">
                  /month
                </div>
              </div>
            </div>
            <div className="shrink-0 self-stretch my-auto ml-10 w-px bg-blue-100 h-[90px]" />
            <div className="flex flex-col flex-1 justify-center items-start self-stretch py-5 pr-20 pl-8 text-lg leading-5 text-black font-[450] max-md:px-5 max-md:max-w-full">
              <div className="flex gap-3 whitespace-nowrap">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d79ee7fccea7b9d927c922f135603abac9c0da64020b7e8d66441234c3f0d59?"
                  className="shrink-0 w-5 aspect-square"
                />
                <div className="grow">1 AI-Generated Posts/day</div>
              </div>
              <div className="flex gap-3 mt-4">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d79ee7fccea7b9d927c922f135603abac9c0da64020b7e8d66441234c3f0d59?"
                  className="shrink-0 w-5 aspect-square"
                />
                <div className="flex-auto">Publish to All Channels</div>
              </div>
              <div className="flex gap-3 mt-4">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d79ee7fccea7b9d927c922f135603abac9c0da64020b7e8d66441234c3f0d59?"
                  className="shrink-0 w-5 aspect-square"
                />
                <div className="flex-auto">Publish to All Channels</div>
              </div>
            </div>
            <button class="justify-center items-center self-stretch px-16 py-2.5 my-auto text-sm font-medium tracking-tight leading-5 text-center text-sky-500 bg-white rounded-3xl border-2 border-sky-500 border-solid max-md:px-5" onClick={openModal} >
              Join the Waitlist
            </button>
          </div>
          <div className="flex gap-4 items-center px-7 py-6 mt-10 max-w-full whitespace-nowrap bg-white rounded-3xl border border-gray-100 border-solid shadow-2xl w-[974px] max-md:flex-wrap max-md:px-5 max-md:mt-10">
            <div className="flex flex-col self-stretch my-auto basis-0">
              <div className="text-xl font-bold text-sky-500">Starter</div>
              <div className="flex gap-0.5 p-1.5 text-black">
                <div className="text-3xl font-medium tracking-tighter text-right">
                  $19
                </div>
                <div className="flex-auto self-start mt-3 text-base font-[450]">
                  /month
                </div>
              </div>
            </div>
            <div className="shrink-0 self-stretch my-auto ml-7 w-px bg-blue-100 h-[90px]" />
            <div className="flex flex-col flex-1 justify-center items-start self-stretch py-5 pr-20 pl-8 text-lg leading-5 text-black font-[450] max-md:px-5 max-md:max-w-full">
              <div className="flex gap-3 whitespace-nowrap">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d79ee7fccea7b9d927c922f135603abac9c0da64020b7e8d66441234c3f0d59?"
                  className="shrink-0 w-5 aspect-square"
                />
                <div className="grow">200 AI-Generated Posts/day</div>
              </div>
              <div className="flex gap-3 mt-4">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d79ee7fccea7b9d927c922f135603abac9c0da64020b7e8d66441234c3f0d59?"
                  className="shrink-0 w-5 aspect-square"
                />
                <div className="flex-auto">Publish to All Channels</div>
              </div>
              <div className="flex gap-3 mt-4">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d79ee7fccea7b9d927c922f135603abac9c0da64020b7e8d66441234c3f0d59?"
                  className="shrink-0 w-5 aspect-square"
                />
                <div className="flex-auto">Publish to All Channels</div>
              </div>
            </div>
            <button class="justify-center items-center self-stretch px-16 py-2.5 my-auto text-sm font-medium tracking-tight leading-5 text-center text-sky-500 bg-white rounded-3xl border-2 border-sky-500 border-solid max-md:px-5" onClick={openModal} >
              Join the Waitlist
            </button>
          </div>
        </div>
      </Section6>
      <Section7 className="flex flex-col items-center self-stretch px-16 pt-20 pb-20 w-full max-md:px-5 max-md:max-w-full">
        <div className="text-5xl font-medium text-center text-black leading-[57px] w-[550px] max-md:max-w-full max-md:text-4xl max-md:leading-[51px]">
          Have questions? We're here to help
        </div>
        <div className="flex flex-col gap-5 justify-between px-12 py-10 mt-9 max-w-full text-xl font-medium leading-8 text-black bg-white rounded-3xl shadow-2xl w-[974px] max-md:flex-wrap max-md:px-5">
          <div className="flex-auto">
            <div className="flex justify-between items-center">
              <div>What is Sentellent?</div>
              <button className="expand-btn" onClick={() => toggleExpand('section1')}>
                {expandedSections.section1 ? "-" : "+"}
              </button>
            </div>
            {expandedSections.section1 && (
              <div className="text-sm leading-7 mt-5 text-opacity-90 max-md:max-w-full" style={{ fontWeight: 450, fontSize: '16px' }}>
                Transform your digital marketing strategy with Sentellent - where innovation meets impact. Leveraging the power of Generative AI technology,
                our marketing automation software revolutionizes how you create and distribute content.
                Imagine generating captivating content at the click of a button,
                scheduling seamless deliveries across top social media platforms,
                and gaining insightful analysis of your marketing efforts, all in one place.
                With Sentellent, you're not just staying ahead of the curve; you're redefining it.
                Elevate your brand's presence, engage your audience like never before, and analyze your success with precision.
                Welcome to the future of digital marketing, where your potential is unlimited
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-5 justify-between px-12 py-10 mt-5 max-w-full text-xl font-medium leading-8 text-black bg-white rounded-3xl shadow-2xl w-[974px] max-md:flex-wrap max-md:px-5">
          <div className="flex-auto">
            <div className="flex justify-between items-center">
              <div>How does the generative AI work?</div>
              <button className="expand-btn" onClick={() => toggleExpand('section2')}>
                {expandedSections.section2 ? "-" : "+"}
              </button>
            </div>
            {expandedSections.section2 && (
              <div className="text-sm leading-7 mt-5 text-opacity-90 max-md:max-w-full" style={{ fontWeight: 450, fontSize: '16px' }}>
                Sentellent's AI is trained on a massive dataset of text and creative
                content. This allows it to understand your intent and generate truly
                unique and relevant social media posts for your brand. Just tell
                Sentellent what kind of post you need, your target audience, and any
                brand guidelines you have, and it will take care of the rest.
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-5 justify-between px-12 py-10 mt-5 max-w-full text-xl font-medium leading-8 text-black bg-white rounded-3xl shadow-2xl w-[974px] max-md:flex-wrap max-md:px-5">
          <div className="flex-auto">
            <div className="flex justify-between items-center">
              <div>Is Sentellent free to use?</div>
              <button className="expand-btn" onClick={() => toggleExpand('section3')}>
                {expandedSections.section3 ? "-" : "+"}
              </button>
            </div>
            {expandedSections.section3 && (
              <div className="text-sm leading-7 mt-5 text-opacity-90 max-md:max-w-full" style={{ fontWeight: 450, fontSize: '16px' }}>
                Start with our complimentary plan to kick off your journey, and continue with our cost-effective options to maintain your momentum
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-5 justify-between px-12 py-10 mt-5 max-w-full text-xl font-medium leading-8 text-black bg-white rounded-3xl shadow-2xl w-[974px] max-md:flex-wrap max-md:px-5">
          <div className="flex-auto">
            <div className="flex justify-between items-center">
              <div>What social media platforms does Sentellent support?</div>
              <button className="expand-btn" onClick={() => toggleExpand('section4')}>
                {expandedSections.section4 ? "-" : "+"}
              </button>
            </div>
            {expandedSections.section4 && (
              <div className="text-sm leading-7 mt-5 text-opacity-90 max-md:max-w-full" style={{ fontWeight: 450, fontSize: '16px' }}>
                We cover all leading social media networks, including LinkedIn, Facebook, Instagram, X, and we're consistently expanding our support to include more platforms
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-5 justify-between px-12 py-10 mt-5 max-w-full text-xl font-medium leading-8 text-black bg-white rounded-3xl shadow-2xl w-[974px] max-md:flex-wrap max-md:px-5">
          <div className="flex-auto">
            <div className="flex justify-between items-center">
              <div>How can I get started with Sentellent?</div>
              <button className="expand-btn" onClick={() => toggleExpand('section5')}>
                {expandedSections.section5 ? "-" : "+"}
              </button>
            </div>
            {expandedSections.section5 && (
              <div className="text-sm leading-7 mt-5 text-opacity-90 max-md:max-w-full" style={{ fontWeight: 450, fontSize: '16px' }}>
                To get started with Sentellent, you can join our waitlist. We'll send you an invite to try out our platform as soon as it's available.
                This is a great opportunity to explore how our marketing automation software can enhance your digital marketing efforts with the power of Generative AI technology.
              </div>
            )}
          </div>
        </div>
      </Section7>
      <Section10 className="flex flex-col self-stretch pt-5 pb-0 w-full max-md:max-w-full">
        <div className="flex overflow-hidden relative flex-col justify-center items-center px-16 py-20 w-full mix-blend-multiply min-h-[509px] max-md:px-5 max-md:max-w-full">
          <div className="flex relative flex-col mt-10 mb-5 max-w-full w-[827px] max-md:mt-10">
            <div className="flex flex-col text-center text-black max-w-[971px]">
              <div className="w-full text-5xl font-black leading-[57px] max-md:max-w-full max-md:text-4xl max-md:leading-[51px]">
                Create Captivating Social Media in Seconds with Sentellent.
              </div>
              <div className="mt-6 w-full text-lg leading-7 font-[450] max-md:max-w-full">
                Stop Wasting Time & Money. Let AI Generate Your Social Media Posts &
                Supercharge Your Marketing with Sentellent.
              </div>
            </div>
            <div className="flex gap-5 justify-center self-center mt-9 font-bold tracking-wide whitespace-nowrap max-md:flex-wrap">
              <div className="flex flex-col flex-1 justify-center">
                <Button onClick={openModal} solid>Join the Waitlist</Button>
              </div>
              <div className="flex flex-col flex-1 justify-center">
                <Button outline onClick={() => window.open('mailto:info@sentellent.com')}>Contact Us</Button>              </div>
            </div>
          </div>
        </div>
      </Section10>
      {/* Footer */}
      <div className="py-12 pr-20 pl-10 bg-black max-md:px-5">
        <div className="flex flex-col w-[32%] max-md:ml-0 max-md:w-full">
          <div className="flex gap-5 justify-between items-start max-md:mt-10">
            <div className="flex flex-col flex-1 self-start text-white">
              <div className="flex gap-3 text-2xl font-medium leading-5 whitespace-nowrap">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3370c5d172bb81ceadbe6a94711163f2eba0b8d94737872680d97ee71bd6c2bb?"
                  className="shrink-0 aspect-[1.2] w-[29px]"
                />
                <div className="flex-auto my-auto">Sentellent</div>
              </div>
            </div>
          </div>
        </div>
        <div className="grow mt-10 max-md:mt-10 max-md:max-w-full">
          <div className="text-xl font-bold tracking-normal text-white">
            Get in touch
          </div>
          <div className="mt-7 text-base tracking-normal leading-7 text-white">
            <span className="leading-5 font-[450]">
              1062 Lincoln Ave #1053, San Jose,
            </span>
            <br />
            <span className="leading-5 font-[450]">
              CA 95125 3141, United States
            </span>
            <br />
            <span className="font-[450]">
              info@Sentellent.com
            </span>
            <br />
            <span className="font-[450]">(480) 890-6620</span>
          </div>
        </div>
      </div>
      {/* Footer */}
    </div>
  );
}

export default LandingPage