import { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { SectionTab } from 'polotno/side-panel'

import { ImagesGrid } from 'polotno/side-panel/images-grid'

import { AVAILABLE_SIZES } from './ResizeSection'
const DEFAULT_SIZE = AVAILABLE_SIZES.FACEBOOK

export const TEMPLATES = [
  {
    'id': '2',
    'preview': '/templates/2-preview.png',
    'width': DEFAULT_SIZE.width,
    'height': DEFAULT_SIZE.height,
    'fonts': [],
    'pages': [
      {
        'id': 'page-2-1',
        'children': [
          {
            "id": "figure-2",
            "type": "svg",
            "name": "",
            "opacity": 1,
            "visible": true,
            "selectable": true,
            "removable": true,
            "alwaysOnTop": false,
            "showInExport": true,
            "x": 421.09917364339094,
            "y": -267.63735177865607,
            "width": 357.8016527132181,
            "height": 357.80165271321783,
            "rotation": 0,
            "animations": [],
            "blurEnabled": false,
            "blurRadius": 10,
            "brightnessEnabled": false,
            "brightness": 0,
            "sepiaEnabled": false,
            "grayscaleEnabled": false,
            "shadowEnabled": false,
            "shadowBlur": 5,
            "shadowOffsetX": 0,
            "shadowOffsetY": 0,
            "shadowColor": "black",
            "shadowOpacity": 1,
            "draggable": true,
            "resizable": true,
            "contentEditable": true,
            "styleEditable": true,
            "src": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PGNpcmNsZSBjeD0iMzAiIGN5PSIzMCIgcj0iMzAiIGZpbGw9InJnYigwLCAxNjEsIDI1NSkiIC8+PC9zdmc+",
            "maskSrc": "",
            "cropX": 0,
            "cropY": 0,
            "cropWidth": 1,
            "cropHeight": 1,
            "keepRatio": false,
            "flipX": false,
            "flipY": false,
            "borderColor": "black",
            "borderSize": 0,
            "cornerRadius": 0,
            "colorsReplace": {
              "rgb(0, 161, 255)": "rgba(224,131,30,1)"
            }
          },
          {
            "id": "image",
            "type": "image",
            "name": "",
            "opacity": 1,
            "visible": true,
            "selectable": true,
            "removable": true,
            "alwaysOnTop": false,
            "showInExport": true,
            "x": 189.14300937954872,
            "y": 200.30382662626153,
            "width": 821.7139812309022,
            "height": 548.4940824716266,
            "rotation": 0,
            "animations": [],
            "blurEnabled": false,
            "blurRadius": 10,
            "brightnessEnabled": false,
            "brightness": 0,
            "sepiaEnabled": false,
            "grayscaleEnabled": false,
            "shadowEnabled": false,
            "shadowBlur": 5,
            "shadowOffsetX": 0,
            "shadowOffsetY": 0,
            "shadowColor": "black",
            "shadowOpacity": 1,
            "draggable": true,
            "resizable": true,
            "contentEditable": true,
            "styleEditable": true,
            "src": "https://images.unsplash.com/photo-1526367790999-0150786686a2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTY5OTZ8MHwxfHNlYXJjaHwxMHx8ZGVsaXZlcnl8ZW58MHx8fHwxNjI4MDU3NTUw&ixlib=rb-1.2.1&q=80&w=1080",
            "cropX": 0,
            "cropY": 0,
            "cornerRadius": 0,
            "flipX": false,
            "flipY": false,
            "clipSrc": "",
            "borderColor": "black",
            "borderSize": 0,
            "keepRatio": false
          },
          {
            "id": "caption-1",
            "type": "text",
            "name": "",
            "opacity": 1,
            "visible": true,
            "selectable": true,
            "removable": true,
            "alwaysOnTop": false,
            "showInExport": true,
            "x": 67.04347823586988,
            "y": 117.0538266262614,
            "width": 1065.91304351826,
            "height": 55,
            "rotation": 0,
            "animations": [],
            "blurEnabled": false,
            "blurRadius": 10,
            "brightnessEnabled": false,
            "brightness": 0,
            "sepiaEnabled": false,
            "grayscaleEnabled": false,
            "shadowEnabled": false,
            "shadowBlur": 5,
            "shadowOffsetX": 0,
            "shadowOffsetY": 0,
            "shadowColor": "black",
            "shadowOpacity": 1,
            "draggable": true,
            "resizable": true,
            "contentEditable": true,
            "styleEditable": true,
            "text": "EXPRESS DELIVERY SERVICE",
            "placeholder": "",
            "fontSize": 48.25513332235936,
            "fontFamily": "Archivo Black",
            "fontStyle": "normal",
            "fontWeight": "normal",
            "textDecoration": "",
            "fill": "rgba(83,49,20,1)",
            "align": "center",
            "verticalAlign": "top",
            "strokeWidth": 0,
            "stroke": "black",
            "lineHeight": 1.1,
            "letterSpacing": 0,
            "backgroundEnabled": false,
            "backgroundColor": "#7ED321",
            "backgroundOpacity": 1,
            "backgroundCornerRadius": 0.5,
            "backgroundPadding": 0.5
          },
          {
            "id": "figure-3",
            "type": "svg",
            "name": "",
            "opacity": 1,
            "visible": true,
            "selectable": true,
            "removable": true,
            "alwaysOnTop": false,
            "showInExport": true,
            "x": 978.2824357377441,
            "y": 377.31726695805025,
            "width": 128,
            "height": 128,
            "rotation": 0,
            "animations": [],
            "blurEnabled": false,
            "blurRadius": 10,
            "brightnessEnabled": false,
            "brightness": 0,
            "sepiaEnabled": false,
            "grayscaleEnabled": false,
            "shadowEnabled": false,
            "shadowBlur": 5,
            "shadowOffsetX": 0,
            "shadowOffsetY": 0,
            "shadowColor": "black",
            "shadowOpacity": 1,
            "draggable": true,
            "resizable": true,
            "contentEditable": true,
            "styleEditable": true,
            "src": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTYgMjU2Ij48cmVjdCB3aWR0aD0iMjU2IiBoZWlnaHQ9IjI1NiIgZmlsbD0ibm9uZSIvPjxjaXJjbGUgY3g9IjYwIiBjeT0iNjAiIHI9IjgiLz48Y2lyY2xlIGN4PSIxMjgiIGN5PSI2MCIgcj0iOCIvPjxjaXJjbGUgY3g9IjE5NiIgY3k9IjYwIiByPSI4Ii8+PGNpcmNsZSBjeD0iNjAiIGN5PSIxMjgiIHI9IjgiLz48Y2lyY2xlIGN4PSIxMjgiIGN5PSIxMjgiIHI9IjgiLz48Y2lyY2xlIGN4PSIxOTYiIGN5PSIxMjgiIHI9IjgiLz48Y2lyY2xlIGN4PSI2MCIgY3k9IjE5NiIgcj0iOCIvPjxjaXJjbGUgY3g9IjEyOCIgY3k9IjE5NiIgcj0iOCIvPjxjaXJjbGUgY3g9IjE5NiIgY3k9IjE5NiIgcj0iOCIvPjwvc3ZnPg==",
            "maskSrc": "",
            "cropX": 0,
            "cropY": 0,
            "cropWidth": 1,
            "cropHeight": 1,
            "keepRatio": true,
            "flipX": false,
            "flipY": false,
            "borderColor": "black",
            "borderSize": 0,
            "cornerRadius": 0,
            "colorsReplace": {}
          },
          {
            "id": "figure-4",
            "type": "svg",
            "name": "",
            "opacity": 1,
            "visible": true,
            "selectable": true,
            "removable": true,
            "alwaysOnTop": false,
            "showInExport": true,
            "x": 160.56845450684153,
            "y": 172.05382662626138,
            "width": 62.8508902445853,
            "height": 62.85089024458531,
            "rotation": 0,
            "animations": [],
            "blurEnabled": false,
            "blurRadius": 10,
            "brightnessEnabled": false,
            "brightness": 0,
            "sepiaEnabled": false,
            "grayscaleEnabled": false,
            "shadowEnabled": false,
            "shadowBlur": 5,
            "shadowOffsetX": 0,
            "shadowOffsetY": 0,
            "shadowColor": "black",
            "shadowOpacity": 1,
            "draggable": true,
            "resizable": true,
            "contentEditable": true,
            "styleEditable": true,
            "src": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjYwIiBoZWlnaHQ9IjYwIiBmaWxsPSJyZ2IoMCwgMTYxLCAyNTUpIiAvPjwvc3ZnPg==",
            "maskSrc": "",
            "cropX": 0,
            "cropY": 0,
            "cropWidth": 1,
            "cropHeight": 1,
            "keepRatio": false,
            "flipX": false,
            "flipY": false,
            "borderColor": "black",
            "borderSize": 0,
            "cornerRadius": 0,
            "colorsReplace": {
              "rgb(0, 161, 255)": "rgba(44,26,11,1)"
            }
          },
          {
            "id": "figure-5",
            "type": "svg",
            "name": "",
            "opacity": 1,
            "visible": true,
            "selectable": true,
            "removable": true,
            "alwaysOnTop": false,
            "showInExport": true,
            "x": 979.4315454931589,
            "y": 172.05382662626144,
            "width": 62.8508902445853,
            "height": 62.85089024458531,
            "rotation": 0,
            "animations": [],
            "blurEnabled": false,
            "blurRadius": 10,
            "brightnessEnabled": false,
            "brightness": 0,
            "sepiaEnabled": false,
            "grayscaleEnabled": false,
            "shadowEnabled": false,
            "shadowBlur": 5,
            "shadowOffsetX": 0,
            "shadowOffsetY": 0,
            "shadowColor": "black",
            "shadowOpacity": 1,
            "draggable": true,
            "resizable": true,
            "contentEditable": true,
            "styleEditable": true,
            "src": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjYwIiBoZWlnaHQ9IjYwIiBmaWxsPSJyZ2IoMCwgMTYxLCAyNTUpIiAvPjwvc3ZnPg==",
            "maskSrc": "",
            "cropX": 0,
            "cropY": 0,
            "cropWidth": 1,
            "cropHeight": 1,
            "keepRatio": false,
            "flipX": false,
            "flipY": false,
            "borderColor": "black",
            "borderSize": 0,
            "cornerRadius": 0,
            "colorsReplace": {
              "rgb(0, 161, 255)": "rgba(44,26,11,1)"
            }
          },
          {
            "id": "RZgM-QR6s0",
            "type": "svg",
            "name": "",
            "opacity": 1,
            "visible": true,
            "selectable": true,
            "removable": true,
            "alwaysOnTop": false,
            "showInExport": true,
            "x": 95.41934475142682,
            "y": 377.3172669580502,
            "width": 128,
            "height": 128,
            "rotation": 0,
            "animations": [],
            "blurEnabled": false,
            "blurRadius": 10,
            "brightnessEnabled": false,
            "brightness": 0,
            "sepiaEnabled": false,
            "grayscaleEnabled": false,
            "shadowEnabled": false,
            "shadowBlur": 5,
            "shadowOffsetX": 0,
            "shadowOffsetY": 0,
            "shadowColor": "black",
            "shadowOpacity": 1,
            "draggable": true,
            "resizable": true,
            "contentEditable": true,
            "styleEditable": true,
            "src": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTYgMjU2Ij48cmVjdCB3aWR0aD0iMjU2IiBoZWlnaHQ9IjI1NiIgZmlsbD0ibm9uZSIvPjxjaXJjbGUgY3g9IjYwIiBjeT0iNjAiIHI9IjgiLz48Y2lyY2xlIGN4PSIxMjgiIGN5PSI2MCIgcj0iOCIvPjxjaXJjbGUgY3g9IjE5NiIgY3k9IjYwIiByPSI4Ii8+PGNpcmNsZSBjeD0iNjAiIGN5PSIxMjgiIHI9IjgiLz48Y2lyY2xlIGN4PSIxMjgiIGN5PSIxMjgiIHI9IjgiLz48Y2lyY2xlIGN4PSIxOTYiIGN5PSIxMjgiIHI9IjgiLz48Y2lyY2xlIGN4PSI2MCIgY3k9IjE5NiIgcj0iOCIvPjxjaXJjbGUgY3g9IjEyOCIgY3k9IjE5NiIgcj0iOCIvPjxjaXJjbGUgY3g9IjE5NiIgY3k9IjE5NiIgcj0iOCIvPjwvc3ZnPg==",
            "maskSrc": "",
            "cropX": 0,
            "cropY": 0,
            "cropWidth": 1,
            "cropHeight": 1,
            "keepRatio": true,
            "flipX": false,
            "flipY": false,
            "borderColor": "black",
            "borderSize": 0,
            "cornerRadius": 0,
            "colorsReplace": {}
          }
        ],
        'background': '#F1BA4B'
      }
    ]
  },
  {
    'id': '1',
    'preview': '/templates/1-preview.png',
    'width': DEFAULT_SIZE.width,
    'height': DEFAULT_SIZE.height,
    'fonts': [],
    'pages': [
      {
        'id': 'page-2-1',
        'children': [
          {
            "id": "image",
            "type": "image",
            "name": "",
            "opacity": 1,
            "visible": true,
            "selectable": true,
            "removable": true,
            "alwaysOnTop": false,
            "showInExport": true,
            "x": 2.8115210316639953e-11,
            "y": 3.279925776301606e-13,
            "width": 683.9506773031094,
            "height": 629.9999999999998,
            "rotation": 0,
            "animations": [],
            "blurEnabled": false,
            "blurRadius": 10,
            "brightnessEnabled": false,
            "brightness": 0,
            "sepiaEnabled": false,
            "grayscaleEnabled": false,
            "shadowEnabled": false,
            "shadowBlur": 5,
            "shadowOffsetX": 0,
            "shadowOffsetY": 0,
            "shadowColor": "black",
            "shadowOpacity": 1,
            "draggable": true,
            "resizable": true,
            "contentEditable": true,
            "styleEditable": true,
            "src": "https://images.unsplash.com/photo-1597078889966-e375fdcd95b4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTY5OTZ8MHwxfHNlYXJjaHw5fHxmYXRoZXJzJTIwZGF5fGVufDB8fHx8MTYzMTk2OTQxNQ&ixlib=rb-1.2.1&q=80&w=1080",
            "cropX": 0,
            "cropY": 0,
            "cornerRadius": 0,
            "flipX": false,
            "flipY": false,
            "clipSrc": "",
            "borderColor": "black",
            "borderSize": 0,
            "keepRatio": false
          },
          {
            "id": "figure-1",
            "type": "svg",
            "name": "",
            "opacity": 1,
            "visible": true,
            "selectable": true,
            "removable": true,
            "alwaysOnTop": false,
            "showInExport": true,
            "x": 684.0681918188346,
            "y": 0,
            "width": 515.9318081811656,
            "height": 630,
            "rotation": 0,
            "animations": [],
            "blurEnabled": false,
            "blurRadius": 10,
            "brightnessEnabled": false,
            "brightness": 0,
            "sepiaEnabled": false,
            "grayscaleEnabled": false,
            "shadowEnabled": false,
            "shadowBlur": 5,
            "shadowOffsetX": 0,
            "shadowOffsetY": 0,
            "shadowColor": "black",
            "shadowOpacity": 1,
            "draggable": true,
            "resizable": true,
            "contentEditable": true,
            "styleEditable": true,
            "src": "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjYwIiBoZWlnaHQ9IjYwIiBmaWxsPSJyZ2IoMCwgMTYxLCAyNTUpIiAvPjwvc3ZnPg==",
            "maskSrc": "",
            "cropX": 0,
            "cropY": 0,
            "cropWidth": 1,
            "cropHeight": 1,
            "keepRatio": false,
            "flipX": false,
            "flipY": false,
            "borderColor": "black",
            "borderSize": 0,
            "cornerRadius": 0,
            "colorsReplace": {
              "rgb(0, 161, 255)": "rgba(32,93,88,1)"
            }
          },
          {
            "id": "caption-1",
            "type": "text",
            "name": "",
            "opacity": 1,
            "visible": true,
            "selectable": true,
            "removable": true,
            "alwaysOnTop": false,
            "showInExport": true,
            "x": 691.5340959044175,
            "y": 314.85708584777115,
            "width": 501,
            "height": 344,
            "rotation": 0,
            "animations": [],
            "blurEnabled": false,
            "blurRadius": 10,
            "brightnessEnabled": false,
            "brightness": 0,
            "sepiaEnabled": false,
            "grayscaleEnabled": false,
            "shadowEnabled": false,
            "shadowBlur": 5,
            "shadowOffsetX": 0,
            "shadowOffsetY": 0,
            "shadowColor": "black",
            "shadowOpacity": 1,
            "draggable": true,
            "resizable": true,
            "contentEditable": true,
            "styleEditable": true,
            "text": "You are truly the best",
            "placeholder": "",
            "fontSize": 114,
            "fontFamily": "Suranna",
            "fontStyle": "normal",
            "fontWeight": "normal",
            "textDecoration": "",
            "fill": "rgba(255,255,255,1)",
            "align": "center",
            "verticalAlign": "top",
            "strokeWidth": 0,
            "stroke": "black",
            "lineHeight": 1.0000000000000002,
            "letterSpacing": 0,
            "backgroundEnabled": false,
            "backgroundColor": "#7ED321",
            "backgroundOpacity": 1,
            "backgroundCornerRadius": 0.5,
            "backgroundPadding": 0.5
          },
          {
            "id": "caption-3",
            "type": "text",
            "name": "",
            "opacity": 1,
            "visible": true,
            "selectable": true,
            "removable": true,
            "alwaysOnTop": false,
            "showInExport": true,
            "x": 716.0340959044174,
            "y": 119.45717848826567,
            "width": 452,
            "height": 51,
            "rotation": 0,
            "animations": [],
            "blurEnabled": false,
            "blurRadius": 10,
            "brightnessEnabled": false,
            "brightness": 0,
            "sepiaEnabled": false,
            "grayscaleEnabled": false,
            "shadowEnabled": false,
            "shadowBlur": 5,
            "shadowOffsetX": 0,
            "shadowOffsetY": 0,
            "shadowColor": "black",
            "shadowOpacity": 1,
            "draggable": true,
            "resizable": true,
            "contentEditable": true,
            "styleEditable": true,
            "text": "To my Dearest Dad!",
            "placeholder": "",
            "fontSize": 41,
            "fontFamily": "Montserrat",
            "fontStyle": "normal",
            "fontWeight": "normal",
            "textDecoration": "",
            "fill": "rgba(255,255,255,1)",
            "align": "center",
            "verticalAlign": "top",
            "strokeWidth": 0,
            "stroke": "black",
            "lineHeight": 1.2,
            "letterSpacing": 0,
            "backgroundEnabled": false,
            "backgroundColor": "#7ED321",
            "backgroundOpacity": 1,
            "backgroundCornerRadius": 0.5,
            "backgroundPadding": 0.5
          }
        ]
      }
    ]
  }
]

export function TemplatesSectionBuilder(changeCallback) {
  const TemplatesPanel = observer(({ store }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isLoading, setIsLoading] = useState(false)

    return (
      <div style={{ height: '100%' }}>
        <ImagesGrid
          shadowEnabled={false}
          images={TEMPLATES}
          isLoading={isLoading}
          getPreview={(item) => {
            return item.preview
          }}
          onSelect={(item) => {
            setIsLoading(true)

            changeCallback(item).then((t) => {
              store.loadJSON(t)
            })

            setIsLoading(false)
          }}
          rowsNumber={1}
        />
      </div>
    )
  })

  const TemplatesSection = {
    name: 'custom-templates',
    Tab: (props) => (
      <SectionTab name="Templates" {...props}>
        <span aria-hidden="true" className="bp5-icon bp5-icon-fullscreen">
          <svg data-icon="control" height="16" role="img" viewBox="0 0 16 16" width="16">
            <path d="M13 8H8v5h5V8zm0-5H8v4h5V3zm2-3H1C.45 0 0 .45 0 1v14c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-1 14H2V2h12v12zM7 3H3v10h4V3z" fillRule="evenodd">
            </path>
          </svg>
        </span>
      </SectionTab>
    ),
    Panel: TemplatesPanel,
  }

  return TemplatesSection
}
