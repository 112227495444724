import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";

export const createAssistantMessage = async (campaignId, message) => {
  try {
    const session = await fetchAuthSession();
    const request = await fetch(
      `${API_SERVER}/groq/campaigns/${campaignId}/assistant/message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({ message }),
      }
    );

    const data = await request.json();
    return data;
  } catch {
    throw new Error("Error interacting with the groq assistant");
  }
};
