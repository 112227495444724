import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";
import { useQueryClient } from "@tanstack/react-query";

import { API_SERVER } from "../config/constants";

import Image from "./image_generation/Image";
import SocialNetworkSelector from "./SocialNetworkSelector";
import Button from "./common/Button";

import { ReactComponent as EditIcon } from "../assets/edit_icon.svg";
import { ReactComponent as ScheduledStatusIcon } from "../assets/scheduled_status_icon.svg";
import { Modal } from "flowbite-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PublishedIcon = () => (
  <svg
    className="w-[18px] h-[18px] mx-1 text-green-500 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
  </svg>
);
const getTomorrowDate = () => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());

  let year = tomorrow.getFullYear();
  let month = ("0" + (tomorrow.getMonth() + 1)).slice(-2); // Months are 0-based
  let day = ("0" + tomorrow.getDate()).slice(-2);

  return year + "-" + month + "-" + day;
};

function convertToEpochTime(dateString, timeString) {
  const dateTimeString = dateString + "T" + timeString + ":00"; // Combine date and time strings
  const epochTime = new Date(dateTimeString).getTime(); // Get epoch time in milliseconds
  return Math.floor(epochTime / 1000); // Convert milliseconds to seconds and return
}

function PostPreviewSide({
  campaign,
  className,
  setChat,
  chat,
  isModelChangeable,
}) {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [showMoreText, setShowMoreText] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setChat(value);
  };

  const handleSocialNetwork = (value) => {
    fetchAuthSession().then((session) => {
      fetch(API_SERVER+`/campaigns/${campaign.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({ post_social_network: value }),
      }).then(async (response) => {
        const data = await response.json();
        queryClient.setQueryData([`campaign-${campaign.id}`], (old) => {
          return { ...old, ...data };
        });
      });
    });
  };

  const [schedule_Post, setschedule_Post] = useState({
    id: "",
    schedule_time: "",
    email: "",
    post_social_network: "",
    post_image_key: "",
    post_image_bucket: "",
    post_caption: "",
    start_date: "",
    start_time: "",
  });

  const schedulePost = () => {
    schedule_Post.id = campaign.id;
    schedule_Post.email = campaign.email;
    schedule_Post.schedule_time = convertToEpochTime(
      schedule_Post.start_date,
      schedule_Post.start_time
    );
    schedule_Post.post_caption = campaign.post_caption;
    schedule_Post.post_image_bucket = campaign.post_image_bucket;
    schedule_Post.post_image_key = campaign.post_image_key;
    schedule_Post.post_social_network = campaign.post_social_network;
    try {
      fetchAuthSession().then((session) => {
        fetch(API_SERVER+`/schedulePost`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.tokens.idToken.toString()}`,
          },
          body: JSON.stringify(schedule_Post),
        })
          .then((response) => {
            setOpenModal(false);
            // refetch();
            if (response.ok) {
              toast.success("Post scheduled successfully!");
            } else {
              throw new Error(response.message || "Failed to publish post.");
            }
          })
          .catch((error) => {
            console.error("Failed to fetch: ", error);
          });
      });
    } catch (error) {
      toast.error("Failed to schedule post. Please try again later.");
    }
  };

  const publishPost = () => {
    setIsPublishing(true);
    const publish = async () => {
      try {
        const session = await fetchAuthSession();
        const response = await fetch(
          API_SERVER+`/campaigns/${campaign.id}/posts/1/publish`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${session.tokens.idToken.toString()}`,
            },
            body: "{}",
          }
        );
        const data = await response.json();
        if (response.status === 200) {
          queryClient.setQueryData([`campaign-${campaign.id}`], (old) => {
            return { ...old, ...data };
          });
          toast.success("Post published successfully!");
        } else {
          throw new Error(data.message || "Failed to publish post.");
        }
      } catch (error) {
        toast.error("Error publishing post. Please try again later.");
      }
    };

    publish();
  };

  const renderState = () => {
    switch (campaign.state) {
      case "PUBLISHED":
        return (
          <>
            <PublishedIcon />
            Published
          </>
        );
      default:
        return (
          <>
            <ScheduledStatusIcon /> Draft
          </>
        );
    }
  };

  const canEdit = useMemo(() => {
    if (isPublishing) return !isPublishing;

    return campaign.state !== "PUBLISHED";
  }, [campaign.state, isPublishing]);

  const [openModal, setOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenedit, setisOpenedit] = useState(false);

  return (
    <div className={className + "flex flex-col justify-start"}>
      <div className="relative">
        <div className="px-4 pt-2 sm:px-1 flex flex-wrap flex-row justify-between gap-1">
          {campaign.post_social_network ? (
            <SocialNetworkSelector
              disabled={!canEdit}
              value={campaign.post_social_network}
              handleSocialNetwork={handleSocialNetwork}
              className="w-24 h-11 rounded-3xl borde lg:basis-[44%] xl:basis-2/12"
            />
          ) : (
            <div className="w-24 h-11 rounded-3xl bg-mid-light-gray lg:basis-[44%] xl:basis-2/12"></div>
          )}
          {isModelChangeable && canEdit ? (
            <Button className="w-40">{chat}</Button>
          ) : (
            <div
              className="relative"
              style={{ color: "#1DA1F2", borderColor: "#1DA1F2" }}
            >
              <div className="relative">
                <Button
                  onClick={() => setisOpenedit(!isOpenedit)}
                  className="w-40"
                >
                  {chat}
                </Button>
                {isOpenedit && (
                  <div className="absolute top-full left-0 mt-1 w-40 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                    <ul className="py-1">
                      <li
                        className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          handleChange({ target: { value: "openAI" } });
                          setisOpenedit(!isOpenedit);
                        }}
                      >
                        OpenAI
                      </li>
                      <li
                        className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          handleChange({ target: { value: "groqAI" } });
                          setisOpenedit(!isOpenedit);
                        }}
                      >
                        GroqAI
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}

          {campaign.post_caption && campaign.post_image && canEdit ? (
            <div className="lg:basis-[43%] xl:basis-[10%] lg:ml-3 flex items-center justify-center">
              <EditIcon
                onClick={() => {
                  navigate(`/mkt/campaigns/${campaign.id}/edit`);
                  window.location.reload();
                }}
                className="text-light-gray hover:cursor-pointer"
              />
            </div>
          ) : (
            <div className="w-11 h-11 rounded-3xl bg-mid-light-gray lg:basis-[43%] xl:basis-[10%]"></div>
          )}

          {campaign.post_caption && campaign.post_image ? (
            <div
              className="relative"
              style={{ color: "#1DA1F2", borderColor: "#1DA1F2" }}
            >
              <Button onClick={() => setIsOpen(!isOpen)} className="w-40">
                Publish
              </Button>
              {isOpen && (
                <div className="absolute top-full left-0 mt-1 w-40 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                  <ul className="py-1">
                    <li
                      className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                      disabled={!canEdit}
                      loading={isPublishing}
                      onClick={() => {
                        setOpenModal(true);
                        setIsOpen(!isOpen);
                      }}
                    >
                      Schedule Post
                    </li>
                    <li
                      className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                      disabled={!canEdit}
                      loading={isPublishing}
                      onClick={() => {
                        publishPost();
                        setIsOpen(!isOpen);
                      }}
                    >
                      Publish
                    </li>
                  </ul>
                </div>
              )}
              <ToastContainer />
            </div>
          ) : (
            <div className="w-24 h-11 rounded-3xl bg-mid-light-gray"></div>
          )}
        </div>
      </div>
      {campaign.post_image_prompt ? (
        <Image campaign={campaign} disabled={canEdit} />
      ) : (
        <div className="h-[463px] bg-mid-light-gray"></div>
      )}
      <div className="">
        {campaign.post_caption ? (
          <div className="p-4">
            {showMoreText ? (
              <p>
                {campaign.post_caption}
                <br />
                <button
                  onClick={() => setShowMoreText(false)}
                  className="font-medium text-main-blue focus:outline-none"
                >
                  Show Less
                </button>
              </p>
            ) : (
              <p>
                {campaign.post_caption.substring(0, 150) + " ... "}
                <button
                  onClick={() => setShowMoreText(true)}
                  className="font-medium text-main-blue focus:outline-none"
                >
                  Show More
                </button>
              </p>
            )}
            <div className="inline-flex items-center font-medium">
              {renderState()}
            </div>
          </div>
        ) : (
          <div className="flex flex-col p-6 space-y-3">
            <div className="h-3 bg-mid-light-gray"></div>
            <div className="h-3 bg-mid-light-gray"></div>
            <div className="h-3 w-1/2 bg-mid-light-gray"></div>
          </div>
        )}
      </div>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header className="items-center">Schedule Post</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div className="relative">
              <input
                type="date"
                value={schedule_Post.start_date}
                onChange={(e) =>
                  setschedule_Post({
                    ...schedule_Post,
                    start_date: e.target.value,
                  })
                }
                min={getTomorrowDate()}
                id="campaign_start_date"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                placeholder=" "
              />
              <label
                htmlFor="campaign_start_date"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Start Date
              </label>
            </div>
            <div className="relative">
              <input
                type="time"
                value={schedule_Post.start_time}
                onChange={(e) =>
                  setschedule_Post({
                    ...schedule_Post,
                    start_time: e.target.value,
                  })
                }
                id="campaign_start_time"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                placeholder=" "
              />
              <label
                htmlFor="campaign_start_date"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Start Time
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-full flex flex-row justify-end space-x-3">
            <Button outline onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={schedulePost}>Schedule Post</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PostPreviewSide;
