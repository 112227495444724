import { observer } from 'mobx-react-lite'
import { SectionTab } from 'polotno/side-panel'
import { Button } from '@blueprintjs/core'

export const AVAILABLE_SIZES = {
  FACEBOOK: { name: 'Facebook Post', width: 1200, height: 630 },
  TWITTER: { name: 'Twitter Post', width: 1600, height: 900 },
}

function resizeElements(oldWidth, oldHeight, newWidth, newHeight, elements) {
  const widthScale = newWidth / oldWidth
  const heightScale = newHeight / oldHeight

  const scaleFactor = Math.min(widthScale, heightScale)

  if (widthScale === 0 || heightScale === 0) {
    return
  }

  elements.forEach(element => {
    if (element.type === 'text') {
      element.set({fontSize: element.fontSize * scaleFactor})
    }

    element.set({
      width: element.width * widthScale,
      height: element.height * heightScale,
      x: element.x * widthScale,
      y: element.y * heightScale
    })
  })
}

export const ResizeSection = {
  name: 'resize',
  Tab: (props) => (
    <SectionTab name="Resize" {...props}>
      <span aria-hidden="true" className="bp5-icon bp5-icon-fullscreen">
        <svg data-icon="fullscreen" height="16" role="img" viewBox="0 0 16 16" width="16">
          <path d="M3.41 2H5c.55 0 1-.45 1-1s-.45-1-1-1H1C.45 0 0 .45 0 1v4c0 .55.45 1 1 1s1-.45 1-1V3.41L5.29 6.7c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71L3.41 2zM6 9c-.28 0-.53.11-.71.29L2 12.59V11c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1H3.41l3.29-3.29c.19-.18.3-.43.3-.71 0-.55-.45-1-1-1zm9 1c-.55 0-1 .45-1 1v1.59L10.71 9.3A.965.965 0 0010 9a1.003 1.003 0 00-.71 1.71l3.3 3.29H11c-.55 0-1 .45-1 1s.45 1 1 1h4c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1zm0-10h-4c-.55 0-1 .45-1 1s.45 1 1 1h1.59l-3.3 3.29a1.003 1.003 0 001.42 1.42L14 3.41V5c0 .55.45 1 1 1s1-.45 1-1V1c0-.55-.45-1-1-1z" fillRule="evenodd">
          </path>
        </svg>
      </span>
    </SectionTab>
  ),

  Panel: observer(({ store }) => {
    return (
      <div>
        {Object.keys(AVAILABLE_SIZES).map((key, i) => (
          <Button
            key={i}
            style={{ width: '100%', marginBottom: '20px' }}
            onClick={() => {
              store.pages.forEach(page => {
                const { computedHeight, computedWidth } = page

                if (page.id === 'page-text') {
                  return;
                }

                page.setSize(
                  {
                    width: AVAILABLE_SIZES[key].width,
                    height: AVAILABLE_SIZES[key].height
                  }
                )

                resizeElements(
                  computedWidth, computedHeight,
                  AVAILABLE_SIZES[key].width,  AVAILABLE_SIZES[key].height,
                  page.children
                )
              })
            }}
          >
            {AVAILABLE_SIZES[key].name} {AVAILABLE_SIZES[key].width}x{AVAILABLE_SIZES[key].height}
          </Button>
        ))}
      </div>
    )
  }),
}
